import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { enqueueSnackbar } from "notistack";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};


export const getProduct = createAsyncThunk(
    "super_admin/getProduct",
    async ({ page, rowsPerPage, searchQuery,selectedBrand,selectedCategory,selectedSize ,selectedFinish }, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/products?page=${page + 1}&limit=${rowsPerPage}&search=${searchQuery || ""}&brand=${selectedBrand || ""}&category=${selectedCategory || ""}&size=${selectedSize || ""}&finish=${selectedFinish || ""}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);
export const getAllInventoryDataSuper = createAsyncThunk(
    "super_admin/getAllInv",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const getInventory = createAsyncThunk(
    "super_admin/getInv",
    async ({ page2, rowsPerPage2, searchQuery,selectedGodown,selectedBrand,selectedCategory,selectedSize ,selectedFinish }, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/inventory?page=${page2 + 1}&limit=${rowsPerPage2}&search=${searchQuery || ""}&godown=${selectedGodown || ""}&brand=${selectedBrand || ""}&category=${selectedCategory || ""}&size=${selectedSize || ""}&finish=${selectedFinish || ""}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const addProduct = createAsyncThunk(
    "super_admin/addProduct",
    async (productData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const formData = new FormData();

            formData.append('name', productData.name);
            formData.append('sku', productData.sku);
            formData.append('size', productData.size);
            formData.append('uom', productData.uom);
            formData.append('product_in_date', productData.product_in_date);
            formData.append('color', productData.color);
            formData.append('brand', productData.brand);
            formData.append('category_id', productData.category_id);
            formData.append('sub_category', productData.sub_category);
            formData.append('thickness', productData.thickness);
            formData.append('finish', productData.finish);

            if (productData.image && productData.image instanceof File) {
                formData.append('image', productData.image);
            }
            if (productData.image2 && productData.image2 instanceof File) {
                formData.append('image2', productData.image2);
            }
            if (productData.image3 && productData.image3 instanceof File) {
                formData.append('image3', productData.image3);
            }
            if (productData.image4 && productData.image4 instanceof File) {
                formData.append('image4', productData.image4);
            }

            const response = await fetch(`${apiUrl}super-admin/product/add`, {
                method: 'POST',
                headers: {

                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Add failed!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }
            enqueueSnackbar("Product added succesfully!", { variant: "success" ,  className: 'outlined-snackbar'});

            const data = await response.json();
            return data;
        } catch (error) {
                        enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });
            
            return rejectWithValue({ message: 'An error occurred while adding the product.' });
        }
    }
);

export const productUpdate = createAsyncThunk(
    "super_admin/productUpdate",
    async (productData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const formData = new FormData();
            formData.append('id', productData.id);
            formData.append('name', productData.name);
            formData.append('brand', productData.brand);
            formData.append('category_id', productData.category_id);
            formData.append('sub_category', productData.sub_category);
            formData.append('size', productData.size);
            formData.append('uom', productData.uom);
            formData.append('product_in_date', productData.product_in_date);
            formData.append('color', productData.color);
            formData.append('description', productData.desc);
            formData.append('thickness', productData.thickness);
            formData.append('finish', productData.finish);
            formData.append('dis_continue', productData.dis_continue);
            formData.append('is_web', productData.is_web);

            if (productData.image && productData.image instanceof File) {
                formData.append('image', productData.image);
            }

            if (productData.image2 && productData.image2 instanceof File) {
                formData.append('image2', productData.image2);
            }
            if (productData.image3 && productData.image3 instanceof File) {
                formData.append('image3', productData.image3);
            }
            if (productData.image4 && productData.image4 instanceof File) {
                formData.append('image4', productData.image4);
            }


            const response = await fetch(`${apiUrl}super-admin/product/update`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Update failed!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }
            enqueueSnackbar("Product updated succesfully!", { variant: "success" ,  className: 'outlined-snackbar'});

            const data = await response.json();
            return data;
        } catch (error) {
                        enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });
            
            return rejectWithValue({ message: 'An error occurred while updating the product.' });
        }
    }
);

export const productDelete = createAsyncThunk(
    "productdelete",
    async (id, { rejectWithValue }) => {
        console.log("Delete thunk called with id:", id);

        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/delete/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Delete failed!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }
            enqueueSnackbar("Product deleted succesfully!", { variant: "success" ,  className: 'outlined-snackbar'});

            const data = await response.json();
            return data;
        } catch (error) {
                        enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });
            
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const initialState = {
    addStatus: 'idle',
    AllProduct: [],
    AllInventory:[],
    allInvDataSuper:[],
    totalProductsSuper:0,
    totalInventorySuper:0,
    getStatus: 'idle',
    invStatus: 'idle',
    updateStatus: 'idle',
    deleteStatus: 'idle',
    otherStatSuper: 'idle',
    error: null,
    product: null,
};

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getProduct.pending, (state) => {
                state.getStatus = 'pending';
            })
            .addCase(getProduct.fulfilled, (state, action) => {
                state.AllProduct = action.payload.data;
                state.totalProductsSuper = action.payload.count;
                state.getStatus = 'succeeded';
            })
            .addCase(getProduct.rejected, (state, action) => {
                state.getStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(getAllInventoryDataSuper.pending, (state) => {
                state.otherStatSuper = 'pending';
            })
            .addCase(getAllInventoryDataSuper.fulfilled, (state, action) => {
                state.allInvDataSuper = action.payload.data;
                state.otherStatSuper = 'succeeded';
            })
            .addCase(getAllInventoryDataSuper.rejected, (state, action) => {
                state.otherStatSuper = 'failed';
                state.error = action.payload;
            })
            .addCase(getInventory.pending, (state) => {
                state.invStatus = 'pending';
            })
            .addCase(getInventory.fulfilled, (state, action) => {
                state.AllInventory = action.payload.data;
                state.totalInventorySuper = action.payload.count;
                console.log(action.payload.count)
                state.invStatus = 'succeeded';
            })
            .addCase(getInventory.rejected, (state, action) => {
                state.invStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addProduct.pending, (state) => {
                state.addStatus = 'pending';
            })
            .addCase(addProduct.fulfilled, (state, action) => {
                state.addStatus = 'succeeded';
                state.product = action.payload;
            })
            .addCase(addProduct.rejected, (state, action) => {
                state.addStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(productUpdate.pending, (state) => {
                state.updateStatus = 'pending';
            })
            .addCase(productUpdate.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';
            })
            .addCase(productUpdate.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(productDelete.pending, (state) => {
                state.deleteStatus = 'pending';
            })
            .addCase(productDelete.fulfilled, (state, action) => {
                state.deleteStatus = 'succeeded';
            })
            .addCase(productDelete.rejected, (state, action) => {
                state.deleteStatus = 'failed';
                state.error = action.payload;
            });
    }
});

export default productSlice.reducer;
