import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const ProtectedRoute = ({ element, allowedRoles }) => {
    const { token, roleId } = useSelector((state) => state.auth);
    const location = useLocation();

    if (!token) {
        return <Navigate to="/" state={{ from: location }} />;
    }

    if (allowedRoles && !allowedRoles.includes(roleId)) {
        return <Navigate to="/" state={{ from: location }} />;
    }

    return element;
};

export default ProtectedRoute;
