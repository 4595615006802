import React, { useState, useEffect } from "react";
import MoveDownIcon from '@mui/icons-material/MoveDown';
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Box, Select, MenuItem } from "@mui/material";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const getCurrentYear = () => new Date().getFullYear();
const getCurrentMonth = () => new Date().getMonth() + 1;

const generateYears = () => {
  const currentYear = getCurrentYear();
  return Array.from(
    { length: currentYear - 2020 + 1 },
    (_, i) => currentYear - i
  );
};

const generateMonths = (selectedYear) => {
  const currentYear = getCurrentYear();
  const currentMonth = getCurrentMonth();
  const maxMonth = selectedYear === currentYear ? currentMonth : 12;
  return Array.from({ length: maxMonth }, (_, i) => i + 1);
};

const StockStatBrand = ({ stockInData, stockInThunk, dispatch }) => {
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonth());

  useEffect(() => {
    const formattedDate = `${selectedYear}-${String(selectedMonth).padStart(
      2,
      "0"
    )}`;
    dispatch(stockInThunk(formattedDate));
  }, [selectedYear, selectedMonth, dispatch, stockInThunk]);

  const labels = stockInData?.length
    ? stockInData.map((item) => item?.name)
    : ["No Data"];
  const stockQuantities = stockInData?.length
    ? stockInData.map((item) => item.total_qty)
    : [0];

  const barChartData = {
    labels,
    datasets: [
      {
        label: "Total Stock In QTY",
        data: stockQuantities,
        backgroundColor: [
          "rgba(255, 99, 132, 0.7)",
          "rgba(54, 162, 235, 0.7)",
          "rgba(255, 206, 86, 0.7)",
          "rgba(75, 192, 192, 0.7)",
          "rgba(153, 102, 255, 0.7)",
          "rgba(255, 159, 64, 0.7)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: { size: 14 },
          color: "#333",
        },
      },
      tooltip: {
        backgroundColor: "rgba(0,0,0,0.8)",
        titleFont: { size: 14 },
        bodyFont: { size: 12 },
      },
    },
    scales: {
      x: {
        grid: { display: false },
        ticks: { font: { size: 12 }, color: "#333" },
      },
      y: {
        grid: { color: "rgba(200,200,200,0.3)" },
        ticks: { font: { size: 12 }, color: "#333" },
      },
    },
  };

  return (
    <div className="mt-10 w-[50%]">
      <Box className="bg-white p-6  rounded-lg shadow-lg border border-gray-300 h-[100%]">
      <div className="flex justify-between w-full gap-3">
        <h2 className="text-2xl font-semibold text-gray-700 mb-4">
          <MoveDownIcon className="mr-3"/> Stock In Statistics (By Brand)
        </h2>

        {/* Filters */}
               <Box className={` flex justify-center gap-4 mb-6`}>
       
          <Select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            className="bg-white border border-gray-400 rounded-lg px-2"
          >
            {generateYears().map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>

          <Select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            className="bg-white border border-gray-400  px-2 rounded-lg "
          >
            {generateMonths(selectedYear).map((month) => (
              <MenuItem key={month} value={month}>
                {month.toString().padStart(2, "0")}
              </MenuItem>
            ))}
          </Select>
        </Box>
        </div>
        <div>
          {/* Chart */}
          <Bar data={barChartData} options={options} height={400} />
        </div>
      </Box>
    </div>
  );
};

export default StockStatBrand;
