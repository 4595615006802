import { configureStore } from '@reduxjs/toolkit'
import authReducer from './Slices/auth/authSlice';
import superAdminCategoryReducer from './Slices/SuperAdmin/categorySlice';
import superAdminUsersReducer from './Slices/SuperAdmin/rolesSlice';
import superAdminGodownReducer from './Slices/SuperAdmin/inventory/godownSlice';
import superAdminBrandReducer from './Slices/SuperAdmin/inventory/brandSlice';
import userReducer from './Slices/SuperAdmin/userSlice';
import productReducer from './Slices/SuperAdmin/productSlice';
import subCategoryReducer from './Slices/SuperAdmin/subCategory';
import superAdminSizeReducer from './Slices/Measures/sizeSlice';
import superAdminUomReducer from './Slices/Measures/uomSlice';
import adminProductReducer from './Slices/Admin/adminProductSlice';
import godownProductReducer from './Slices/Godown/productSlice';
import stockReducer from './Slices/Admin/Stocks/stocksSlice';
import bulkProductReducer from './Slices/BulkProductAdd/bulkAdd';
import superDeviceReducer from './Slices/SuperAdmin/deviceSlice';
import adminHoldReducer from './Slices/Hold/holdSlice';
import godownReportReducer from './Slices/Report/reportSlice';
import superAdminFinishReducer from './Slices/SuperAdmin/finishSlice';
import uploadsReducer from './Slices/SuperAdmin/web/uploadSlice';
import enquiryReducer from './Slices/SuperAdmin/web/enquirySlice';
import requestReducer from './Slices/SuperAdmin/web/requestSlice';
import superReportDeleteReducer from './Slices/SuperAdmin/reportDeleteSlice';
import dashReducer from './Slices/dash/dashSlice';
export const store = configureStore({
    reducer: {
        auth: authReducer,  //  login
        superAdminCategory: superAdminCategoryReducer, // category
        superAdminRoles: superAdminUsersReducer, // user / roles
        superAdminGodown: superAdminGodownReducer, // inventory- godown
        superAdminBrand: superAdminBrandReducer, // inventory- brand
        user: userReducer, // user add / update/ delete
        godownProduct: godownProductReducer, // user add / update/ delete
        product: productReducer, // product add update/ delete
        adminProduct: adminProductReducer,
        stock: stockReducer,
        subCategory: subCategoryReducer, // subCategory add update/ delete
        superAdminSize: superAdminSizeReducer, // size
        superAdminUom: superAdminUomReducer, // UOM
        bulkProduct: bulkProductReducer,
        device: superDeviceReducer,
        hold: adminHoldReducer,
        report: godownReportReducer,
        finish: superAdminFinishReducer,
        webUploads: uploadsReducer,
        request: requestReducer,
        enquiry: enquiryReducer,
        reprtDelete: superReportDeleteReducer,
        dash: dashReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});