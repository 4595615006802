import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../../apiConfig";
import { enqueueSnackbar } from "notistack";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const getRequest = createAsyncThunk(
    "super_admin/getRequest",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/request`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const initialState = {
    requestData: [],
    getStat: 'idle',
    deleteStat: 'idle',
    error: null,
};

const requestsSlice = createSlice({
    name: 'request',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getRequest.pending, (state) => {
                state.getStat = 'pending';
            })
            .addCase(getRequest.fulfilled, (state, action) => {
                state.getStat = 'succeeded';
                state.requestData = action.payload.data;
            })
            .addCase(getRequest.rejected, (state, action) => {
                state.getStat = 'failed';
                state.error = action.payload;
            });
    }
});

export default requestsSlice.reducer;

