import React, { useEffect } from "react";
import AdminTopbar from "./Layout/AdminTopbar";
import { getAdminProduct } from "../../redux/Slices/Admin/adminProductSlice";
import { useDispatch, useSelector } from "react-redux";
import { getCategory } from "../../redux/Slices/SuperAdmin/categorySlice";
import { getBrand } from "../../redux/Slices/SuperAdmin/inventory/brandSlice";
import { FaBoxOpen, FaTag, FaClipboardList, FaWarehouse } from "react-icons/fa";
import { Link } from "react-router-dom";
import BrandGraph from "../../components/BrandGraph";
import StockStat from "../../components/StockStat";
import StockOutStat from "../../components/StockOutStat";
import {
  getBrandData,
  getAdminStockInStat,
  getAdminStockOutStat,
  getAdminStockInStatBrand,
  getAdminStockOutStatBrand,
  getAdminStockOutCat,
  getAdminStockInCat,
} from "../../redux/Slices/dash/dashSlice";
import StockStatBrand from "../../components/StockStatBrand";
import StockOutStatBrand from "../../components/StockOutStatBrand";
import StockOutStatCat from "../../components/StockOutStatCat";
import StockStatCat from "../../components/StockStatCat";

const Admin = () => {
  const { allInvDataAdmin = [] } = useSelector((state) => state.adminProduct);
  const { brandData = [] } = useSelector((state) => state.superAdminBrand);
  const { data = [] } = useSelector((state) => state.superAdminCategory);

  const dispatch = useDispatch();
  const {
    brandStat,
    adminStockInStat,
    adminStockOutStat,
    adminStockInStatBrand,
    adminStockOutStatBrand,
    adminStockInStatCat,
    adminStockOutStatCat,
    status,
  } = useSelector((state) => state.dash);

  useEffect(() => {
    dispatch(getAdminProduct());
    dispatch(getBrand());
    dispatch(getCategory());
    dispatch(getBrandData());
  }, [dispatch]);

  // const totalProducts = allInvDataAdmin.length;
  // const totalInventories = allInvDataAdmin.reduce((acc, product) => acc + product.inventories.length, 0);
  // const totalBatches = allInvDataAdmin.reduce((acc, product) => {
  //     return acc + product.inventories.reduce((acc2, inventory) => {
  //         return acc2 + (inventory.holdqty ? inventory.holdqty.length : 0);
  //     }, 0);
  // }, 0);
  // const availableProducts = allInvDataAdmin.filter(product => product.inventories && product.inventories.length > 0);
  // const totalAvailableProducts = availableProducts.length;
  // const stoppedProduct = allInvDataAdmin.filter(product => product.dis_continue === '0');
  // const stopCount = stoppedProduct.length;

  const totalProducts = localStorage.getItem("totalProductsA");
  const totalInventories = localStorage.getItem("totalInventoriesA");
  const holdProduct = localStorage.getItem("totalBatchesA");
  const totalAvailableProducts = localStorage.getItem(
    "totalAvailableProductsA"
  );
  const stopCount = localStorage.getItem("stopCountA");
  return (
    <>
      <AdminTopbar />
      <div className="p-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          {/* Total Products Card */}
          <div className="bg-gradient-to-r from-blue-400 to-blue-600 p-3 rounded-lg shadow-lg transition-transform transform hover:scale-105">
            <div className="flex flex-col justify-between h-32">
              <div className="flex justify-around gap-20">
                <FaBoxOpen className="text-4xl text-white mb-2" />
                <span className="flex flex-col items-end">
                  <h2 className="font-bold text-lg text-white uppercase">
                    Total Products
                  </h2>
                  <p className="text-2xl text-white">{totalProducts}</p>
                </span>
              </div>
              <span className="flex justify-around gap-20 items-end">
                <span className="flex flex-col ">
                  <p className="text-sm text-white">
                    Available : {totalAvailableProducts}{" "}
                  </p>
                  <p className="text-sm text-white">
                    Discontinued : {stopCount}{" "}
                  </p>
                </span>
                <Link
                  to="/admin-products"
                  className="text-white underline mt-2 text-right hover:text-blue-200"
                >
                  Know More
                </Link>
              </span>
            </div>
          </div>

          {/* Available Batches Card */}
          <div className="bg-gradient-to-r from-green-400 to-green-600 p-3 rounded-lg shadow-lg transition-transform transform hover:scale-105">
            <div className="flex flex-col justify-between h-32">
              <div className="flex justify-around gap-32">
                <FaClipboardList className="text-4xl text-white mb-2" />
                <span className="flex flex-col  items-end">
                  <h2 className="font-bold text-lg text-white uppercase">
                    Available Batches
                  </h2>
                  <p className="text-2xl text-white">{totalInventories}</p>
                </span>
              </div>
              <Link
                to="report-in"
                className="text-white underline mt-2 text-right hover:text-green-200"
              >
                Know More
              </Link>
            </div>
          </div>

          {/* Products On Hold Card */}
          <div className="bg-gradient-to-r from-yellow-400 to-yellow-600 p-3 rounded-lg shadow-lg transition-transform transform hover:scale-105">
            <div className="flex flex-col justify-between h-32">
              <div className="flex justify-around gap-32">
                <FaWarehouse className="text-4xl text-white mb-2" />
                <span className="flex flex-col  items-end">
                  <h2 className="font-bold text-lg text-white uppercase">
                    Products On Hold
                  </h2>
                  <p className="text-2xl text-white">{holdProduct}</p>
                </span>
              </div>
              <Link
                to="/hold"
                className="text-white underline mt-2 text-right hover:text-yellow-200"
              >
                Know More
              </Link>
            </div>
          </div>

          {/* Total Brands Card */}
          <div className="bg-gradient-to-r from-purple-400 to-purple-600 p-3 rounded-lg shadow-lg transition-transform transform hover:scale-105">
            <div className="flex flex-col justify-between h-32">
              <div className="flex justify-around gap-32">
                <FaTag className="text-4xl text-white mb-2" />
                <span className="flex flex-col  items-end">
                  <h2 className="font-bold text-lg text-white uppercase">
                    Total Brands
                  </h2>
                  <p className="text-2xl text-white">{brandData.length}</p>
                </span>
              </div>
              <Link
                to="admin-brands"
                className="text-white underline mt-2 text-right hover:text-purple-200"
              >
                Know More
              </Link>
            </div>
          </div>

          {/* Number of Categories Card */}
          <div className="bg-gradient-to-r from-orange-400 to-orange-600 p-3 rounded-lg shadow-lg transition-transform transform hover:scale-105">
            <div className="flex flex-col justify-between h-32">
              <div className="flex justify-around gap-32">
                <FaTag className="text-4xl text-white mb-2" />
                <span className="flex flex-col  items-end">
                  <h2 className="font-bold text-lg text-white uppercase">
                    No. of Categories
                  </h2>
                  <p className="text-2xl text-white">{data.length}</p>
                </span>
              </div>
              <Link
                to="/admin-category"
                className="text-white underline mt-2 text-right hover:text-orange-200"
              >
                Know More
              </Link>
            </div>
          </div>
        </div>
        <BrandGraph data={brandStat} title={"Inventory by Brand"} />
        <div className="flex justify-between w-full gap-3">
          <StockStat
            stockInData={adminStockInStat}
            stockInThunk={getAdminStockInStat}
            dispatch={dispatch}
          />
          <StockStatBrand
            stockInData={adminStockInStatBrand}
            stockInThunk={getAdminStockInStatBrand}
            dispatch={dispatch}
          />
        </div>
        <div className="flex justify-between w-full gap-3">
          <StockOutStat
            stockOutData={adminStockOutStat}
            stockOutThunk={getAdminStockOutStat}
            dispatch={dispatch}
          />
          <StockOutStatBrand
            stockOutData={adminStockOutStatBrand}
            stockOutThunk={getAdminStockOutStatBrand}
            dispatch={dispatch}
          />
        </div>
        <div className="flex justify-between w-full gap-3">
          <StockStatCat
            stockInData={adminStockInStatCat}
            stockInThunk={getAdminStockInCat}
            dispatch={dispatch}
          />
          <StockOutStatCat
            stockOutData={adminStockOutStatCat}
            stockOutThunk={getAdminStockOutCat}
            dispatch={dispatch}
          />
        </div>
      </div>
    </>
  );
};

export default Admin;
