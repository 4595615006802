import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { enqueueSnackbar } from "notistack";

const getTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const getBrandData = createAsyncThunk(
  "super_admin/getBrandData",
  async (_, { rejectWithValue }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await fetch(`${apiUrl}super-admin/brand/recent`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          Accept: "*/*",
        },
      });

      if (!response.ok) {
        const errorData = await response.json();
        enqueueSnackbar(errorData.error || "Something went wrong!", {
          variant: "error",
          className: "outlined-snackbar",
        });
        return rejectWithValue(errorData);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
        className: "outlined-snackbar",
      });

      return rejectWithValue({
        message: "An error occurred during the request.",
      });
    }
  }
);
export const getStockInStat = createAsyncThunk(
  "super_admin/getStockInStat",
  async (date, { rejectWithValue }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await fetch(
        `${apiUrl}super-admin/product/stock-in-recent/${date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        enqueueSnackbar(errorData.error || "Something went wrong!", {
          variant: "error",
          className: "outlined-snackbar",
        });
        return rejectWithValue(errorData);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
        className: "outlined-snackbar",
      });

      return rejectWithValue({
        message: "An error occurred during the request.",
      });
    }
  }
);
export const getStockOutStat = createAsyncThunk(
  "super_admin/getStockOutStat",
  async (date, { rejectWithValue }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await fetch(
        `${apiUrl}super-admin/product/stock-out/${date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        enqueueSnackbar(errorData.error || "Something went wrong!", {
          variant: "error",
          className: "outlined-snackbar",
        });
        return rejectWithValue(errorData);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
        className: "outlined-snackbar",
      });

      return rejectWithValue({
        message: "An error occurred during the request.",
      });
    }
  }
);
export const getAdminStockInStat = createAsyncThunk(
  "admin/getAdminStockInStat",
  async (date, { rejectWithValue }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await fetch(
        `${apiUrl}admin/product/stock-in/recent/${date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        enqueueSnackbar(errorData.error || "Something went wrong!", {
          variant: "error",
          className: "outlined-snackbar",
        });
        return rejectWithValue(errorData);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
        className: "outlined-snackbar",
      });

      return rejectWithValue({
        message: "An error occurred during the request.",
      });
    }
  }
);
export const getAdminStockOutStat = createAsyncThunk(
  "admin/getAdminStockOutStat",
  async (date, { rejectWithValue }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await fetch(
        `${apiUrl}admin/product/stock-out/recent/${date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        enqueueSnackbar(errorData.error || "Something went wrong!", {
          variant: "error",
          className: "outlined-snackbar",
        });
        return rejectWithValue(errorData);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
        className: "outlined-snackbar",
      });

      return rejectWithValue({
        message: "An error occurred during the request.",
      });
    }
  }
);

export const getStockInStatBrand = createAsyncThunk(
  "super_admin/getStockInStatBrand",
  async (date, { rejectWithValue }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await fetch(
        `${apiUrl}super-admin/brand/product-brands-stock-in/${date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        enqueueSnackbar(errorData.error || "Something went wrong!", {
          variant: "error",
          className: "outlined-snackbar",
        });
        return rejectWithValue(errorData);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
        className: "outlined-snackbar",
      });

      return rejectWithValue({
        message: "An error occurred during the request.",
      });
    }
  }
);
export const getStockOutStatBrand = createAsyncThunk(
  "super_admin/getStockOutStatBrand",
  async (date, { rejectWithValue }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await fetch(
        `${apiUrl}super-admin/brand/product-brands-stock-out/${date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        enqueueSnackbar(errorData.error || "Something went wrong!", {
          variant: "error",
          className: "outlined-snackbar",
        });
        return rejectWithValue(errorData);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
        className: "outlined-snackbar",
      });

      return rejectWithValue({
        message: "An error occurred during the request.",
      });
    }
  }
);
export const getAdminStockInStatBrand = createAsyncThunk(
  "admin/getAdminStockInStatBrand",
  async (date, { rejectWithValue }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await fetch(
        `${apiUrl}admin/brand/stock-in/${date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        enqueueSnackbar(errorData.error || "Something went wrong!", {
          variant: "error",
          className: "outlined-snackbar",
        });
        return rejectWithValue(errorData);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
        className: "outlined-snackbar",
      });

      return rejectWithValue({
        message: "An error occurred during the request.",
      });
    }
  }
);
export const getAdminStockOutStatBrand = createAsyncThunk(
  "admin/getAdminStockOutStatBrand",
  async (date, { rejectWithValue }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await fetch(
        `${apiUrl}admin/brand/stock-out/${date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        enqueueSnackbar(errorData.error || "Something went wrong!", {
          variant: "error",
          className: "outlined-snackbar",
        });
        return rejectWithValue(errorData);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
        className: "outlined-snackbar",
      });

      return rejectWithValue({
        message: "An error occurred during the request.",
      });
    }
  }
);
export const getAdminStockInCat = createAsyncThunk(
  "admin/getCatStatInAdmin",
  async (date, { rejectWithValue }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await fetch(
        `${apiUrl}admin/category/stock-in/${date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        enqueueSnackbar(errorData.error || "Something went wrong!", {
          variant: "error",
          className: "outlined-snackbar",
        });
        return rejectWithValue(errorData);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
        className: "outlined-snackbar",
      });

      return rejectWithValue({
        message: "An error occurred during the request.",
      });
    }
  }
);
export const getAdminStockOutCat = createAsyncThunk(
  "admin/getCatStatOutAdm",
  async (date, { rejectWithValue }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await fetch(
        `${apiUrl}admin/category/stock-out/${date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        enqueueSnackbar(errorData.error || "Something went wrong!", {
          variant: "error",
          className: "outlined-snackbar",
        });
        return rejectWithValue(errorData);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
        className: "outlined-snackbar",
      });

      return rejectWithValue({
        message: "An error occurred during the request.",
      });
    }
  }
);
export const getSuperStockInCat = createAsyncThunk(
  "admin/getCatStat",
  async (date, { rejectWithValue }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await fetch(
        `${apiUrl}super-admin/category/stock-in/${date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        enqueueSnackbar(errorData.error || "Something went wrong!", {
          variant: "error",
          className: "outlined-snackbar",
        });
        return rejectWithValue(errorData);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
        className: "outlined-snackbar",
      });

      return rejectWithValue({
        message: "An error occurred during the request.",
      });
    }
  }
);
export const getSuperStockOutCat = createAsyncThunk(
  "admin/getcatsupout",
  async (date, { rejectWithValue }) => {
    try {
      const token = getTokenFromLocalStorage();
      const response = await fetch(
        `${apiUrl}super-admin/category/stock-out/${date}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            Accept: "*/*",
          },
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        enqueueSnackbar(errorData.error || "Something went wrong!", {
          variant: "error",
          className: "outlined-snackbar",
        });
        return rejectWithValue(errorData);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      enqueueSnackbar("Something went wrong!", {
        variant: "error",
        className: "outlined-snackbar",
      });

      return rejectWithValue({
        message: "An error occurred during the request.",
      });
    }
  }
);

const dashSlice = createSlice({
  name: "dash",
  initialState: {
    brandStat: [],
    catStat: [],
    stockInStat: [],
    stockOutStat: [],
    adminStockInStat: [],
    adminStockOutStat: [],
    stockInStatBrand: [],
    stockOutStatBrand: [],
    adminStockInStatBrand: [],
    adminStockOutStatBrand: [],
    stockInStatCat: [],
    stockOutStatCat: [],
    adminStockInStatCat: [],
    adminStockOutStatCat: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getBrandData.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getBrandData.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.brandStat = action.payload.data.sort(
          (a, b) => new Date(b.product_count) - new Date(a.product_count)
        );
        console.log(action.payload.data)
      })
      .addCase(getBrandData.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getStockInStat.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getStockInStat.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.stockInStat = action.payload.data;
      })
      .addCase(getStockInStat.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getStockOutStat.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getStockOutStat.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.stockOutStat = action.payload.data;
      })
      .addCase(getStockOutStat.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getAdminStockInStat.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getAdminStockInStat.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.adminStockInStat = action.payload.data;
      })
      .addCase(getAdminStockInStat.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getAdminStockOutStat.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getAdminStockOutStat.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.adminStockOutStat = action.payload.data;
      })
      .addCase(getAdminStockOutStat.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getStockInStatBrand.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getStockInStatBrand.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.stockInStatBrand = action.payload.data;
      })
      .addCase(getStockInStatBrand.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getStockOutStatBrand.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getStockOutStatBrand.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.stockOutStatBrand = action.payload.data;
      })
      .addCase(getStockOutStatBrand.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getAdminStockInStatBrand.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getAdminStockInStatBrand.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.adminStockInStatBrand = action.payload.data;
      })
      .addCase(getAdminStockInStatBrand.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getAdminStockOutStatBrand.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getAdminStockOutStatBrand.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.adminStockOutStatBrand = action.payload.data;
      })
      .addCase(getAdminStockOutStatBrand.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getSuperStockInCat.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getSuperStockInCat.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.stockInStatCat = action.payload.data;
      })
      .addCase(getSuperStockInCat.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getSuperStockOutCat.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getSuperStockOutCat.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.stockOutStatCat = action.payload.data;
      })
      .addCase(getSuperStockOutCat.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getAdminStockInCat.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getAdminStockInCat.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.adminStockInStatCat = action.payload.data;
      })
      .addCase(getAdminStockInCat.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(getAdminStockOutCat.pending, (state) => {
        state.status = "pending";
      })
      .addCase(getAdminStockOutCat.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.adminStockOutStatCat = action.payload.data;
      })
      .addCase(getAdminStockOutCat.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default dashSlice.reducer;
