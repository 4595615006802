import React, { useEffect, useState } from 'react';
import { getCategory, addCategory, updateCategory, categoryDelete } from '../../../redux/Slices/SuperAdmin/categorySlice';
import { useDispatch, useSelector } from 'react-redux';
import { addSubCategory, subCategoryUpdate, subCategoryDelete } from '../../../redux/Slices/SuperAdmin/subCategory';
import { FaRegEdit, FaTrash } from 'react-icons/fa';
import { AiOutlinePlus } from 'react-icons/ai';
 import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination, IconButton
} from '@mui/material';
import Loader from '../../../components/utils/Loader';
import Topbar from '../../../components/Layout/Topbar';
import { useNavigate, useNavigation } from 'react-router-dom';

const Category = () => {

    const { data = [], getStatus, updateStatus, addStatus, deleteStatus } = useSelector(state => state.superAdminCategory);
    const { addSubStat, deleteStat, updateStat } = useSelector(state => state.subCategory);
    const [categoryName, setCategoryName] = useState('');
    const [selectedCategoryId, setSelectedCategoryId] = useState('');
    const [updatedCategoryName, setUpdatedCategoryName] = useState('');
    const [searchQuery, setSearchQuery] = useState("");
    const [filteredData, setFilteredData] = useState(data);
    const [selectedCat, setSelectedCat] = useState({});
    const [updateForm, setUpdateForm] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const [subCatModal, setSubCatModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState({});
    const [subCategory, setSubCategory] = useState();
    const dispatch = useDispatch();
    const [type, setType] = useState('');
    const [prevAddStatus, setPrevAddStatus] = useState('');
    const [prevUpdateStatus, setPrevUpdateStatus] = useState('');
    const [prevDeleteStatus, setPrevDeleteStatus] = useState('');
    const { roleId } = useSelector((state) => state.auth);
    const navigate = useNavigate();


    useEffect(() => {
        dispatch(getCategory());
    }, [dispatch, updateStatus, updateStat, addStatus, deleteStatus, addSubStat, deleteStat]);

    const handleAddCategory = (e) => {
        e.preventDefault();
        dispatch(addCategory({ name: categoryName }));
        setCategoryName('');
    };
    console.log(addStatus)
    const handleAddSubCategory = (e) => {
        e.preventDefault();
        dispatch(addSubCategory({ name: subCategory, category_id: selectedCategory.id }));
        setSelectedCat({});
    };

    const handleUpdateCategory = (e) => {
        e.preventDefault();
        if (type == 'cat') {
            dispatch(updateCategory({ id: selectedItem.id, name: updatedCategoryName }));
        } else {
            dispatch(subCategoryUpdate({ id: selectedItem.id, name: updatedCategoryName }));
        }
        setUpdatedCategoryName('');
        setType('');
    };

    useEffect(() => {
        const lowerCaseQuery = searchQuery.toLowerCase();
        setFilteredData(
            data.filter(cat =>
                String(cat.name).toLowerCase().includes(lowerCaseQuery)
            )
        );
    }, [searchQuery, data]);

    const handleItemClick = (category, type) => {
        setSelectedItem(category);
        setUpdateForm(true);
        setType(type);
    };

    const handleSelectedItem = (item) => {
        setSelectedCategory(item);
        setSubCatModal(true);
    };

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        const value = event.target.value;
        setRowsPerPage(value === 'all' ? filteredData.length : parseInt(value, 10));
        setPage(0);
    };

    const isPending = getStatus === 'pending' || updateStatus === 'pending' || updateStat === 'pending' || addStatus === 'pending' || deleteStatus === 'pending';

    useEffect(() => {
        if (addStatus === 'succeeded' || updateStatus === 'succeeded' || updateStat === 'pending' || addSubStat === 'succeeded') {
            setUpdateForm(false);
            setSubCatModal(false)
        }
    }, [addStatus, updateStatus, updateStat, addSubStat, deleteStat]);

    return (
        <>
            {(roleId === '1' || roleId === '4') && (
                <Topbar activeTab={'category'} />
            )}
            {isPending && (
                <Loader />
            )}


            {/* add sub category  */}
            {subCatModal && (
                <div className="fixed z-10 inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                        <div className="flex justify-between mb-4">
                            <h2 className="font-semibold text-lg">Add Sub Category</h2>
                            <button
                                className="bg-gray-700 rounded-full p-1 hover:text-gray-300 text-white"
                                onClick={() => setSubCatModal(false)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>
                        <form onSubmit={handleAddSubCategory}>
                            <div className="flex items-center mb-4">
                                <input
                                    type="text"
                                    value={subCategory}
                                    onChange={(e) => setSubCategory(e.target.value)}
                                    placeholder="Enter sub category"
                                    className="w-full p-2 py-3 pl-10 text-sm text-gray-700 border border-gray-300 rounded"
                                />
                                <button
                                    type="submit"
                                    className="bg-orange-500 py-3  hover:bg-orange-700 text-sm text-white font-bold px-7 rounded ml-2"
                                >
                                    Add
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* category update modal */}
            {updateForm && (

                <div className="fixed z-10 inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg w-1/3">
                        <div className="flex justify-between mb-4">
                            <h2 className="font-semibold text-lg">Update Category</h2>
                            <button
                                className="bg-gray-700 rounded-full p-1 hover:text-gray-300 text-white"
                                onClick={() => setUpdateForm(false)}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-6 w-6"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                >
                                    <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M6 18L18 6M6 6l12 12"
                                    />
                                </svg>
                            </button>
                        </div>

                        <div className="mb-4">
                            <p className="text-gray-600">Selected Category:</p>
                            <p className="text-lg">{selectedItem.id} - {selectedItem.name}</p>
                        </div>

                        <form onSubmit={handleUpdateCategory}>
                            <div className="flex items-center mb-4 gap-4">
                                <input
                                    type="text"
                                    value={updatedCategoryName}
                                    onChange={(e) => setUpdatedCategoryName(e.target.value)}
                                    placeholder="Enter updated value"
                                    className="w-full p-2 py-3 pl-10 text-sm text-gray-700 border border-gray-300 rounded"
                                />
                                <button
                                    type="submit"
                                    className="bg-orange-500 py-3 hover:bg-orange-700 text-sm text-white font-bold px-4 rounded ml-2"
                                >
                                    Update
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            <div className='p-5'>

                {/* Search and Add Category Section */}
                <span className='flex items-center justify-between bg-gray-700 rounded-t-lg py-4'>
                    <div className="mb-4 ml-5">
                        <input
                            type="text"
                            className="rounded w-[20rem] py-3 px-4 border border-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500"
                            placeholder="Search by any field"
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>

                    <button
                        onClick={() => navigate('/finish')}
                        className="bg-orange-500 py-3 mb-2 px-5 text-white  rounded hover:bg-orange-700 transition-all duration-300"
                    >
                        FINISH
                    </button>
                    {roleId !== '4' && (
                        <div className="flex justify-end mr-5 mb-4 gap-4">
                            <form onSubmit={handleAddCategory}>
                                <div className="flex items-center gap-2">
                                    <input
                                        type="text"
                                        value={categoryName}
                                        onChange={(e) => setCategoryName(e.target.value)}
                                        placeholder="Enter category name"
                                        className="w-[18rem] p-2 py-3 pl-4 text-sm text-gray-700 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-orange-400"
                                    />
                                    <button
                                        type="submit"
                                        className="bg-orange-500 py-3 px-5 text-white rounded hover:bg-orange-700 transition-all duration-300"
                                    >
                                        Add
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </span>

                {/* Category List Table */}
                <Paper className="-mt-5 shadow-md">
                    <TableContainer>
                        <Table>
                            <TableHead className='bg-gray-700'>
                                <TableRow>
                                    <TableCell sx={{ color: 'white' }}>SL No.</TableCell>
                                    <TableCell sx={{ color: 'white' }}>ID</TableCell>
                                    <TableCell sx={{ color: 'white' }}>Category</TableCell>
                                    <TableCell sx={{ color: 'white' }}>Sub-Category</TableCell>
                                    {roleId !== '4' && (
                                        <TableCell sx={{ color: 'white' }}>Action</TableCell>
                                    )}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((category, index) => (
                                        <TableRow key={category.id} className="hover:bg-gray-50 transition-colors">
                                            <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                                            <TableCell>{category.id}</TableCell>
                                            <TableCell>{category.name}</TableCell>

                                            {/* Subcategory list */}
                                            <TableCell>
                                                <div className='flex flex-col'>
                                                    {category.subcategories.length > 0 && (

                                                        <table className="min-w-full bg-gray-100 rounded-md border border-gray-300">
                                                            <thead className="bg-gray-200">
                                                                <tr>
                                                                    <th className="text-left text-xs font-medium text-gray-700 px-4 py-2"> ID</th>
                                                                    <th className="text-left text-xs font-medium text-gray-700 px-4 py-2"> Name</th>
                                                                    {roleId !== '4' && (
                                                                        <th className="text-center text-xs font-medium text-gray-700 px-4 py-2">Action</th>
                                                                    )}
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {category?.subcategories?.map((item) => (
                                                                    <tr key={item.id} className="hover:bg-gray-200 transition duration-200 rounded">
                                                                        <td className="text-xs text-blue-600 px-4 py-2">{item.id}</td>
                                                                        <td className="text-sm text-gray-700 px-4 py-2">{item.name}</td>
                                                                        {roleId !== '4' && (
                                                                            <td className="text-center px-4 py-2">
                                                                                <div className="flex gap-2">
                                                                                    <FaRegEdit
                                                                                        onClick={() => handleItemClick(item, 'subCat')}
                                                                                        className="text-blue-500 hover:text-blue-700 transition duration-200"
                                                                                        size={14}
                                                                                    />
                                                                                    <FaTrash
                                                                                        onClick={() => dispatch(subCategoryDelete(item.id))}
                                                                                        className="text-red-500 cursor-pointer hover:text-red-700 transition duration-200"
                                                                                        size={14}
                                                                                    />
                                                                                </div>
                                                                            </td>
                                                                        )}
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>

                                                    )}
                                                    {/* Add Subcategory Button (if applicable) */}
                                                    {roleId !== '4' && (
                                                        <div className="flex justify-center mt-2">
                                                            <button
                                                                onClick={() => handleSelectedItem(category)}
                                                                className="flex items-center px-3 py-2 bg-green-500 text-white text-xs font-semibold rounded-md hover:bg-green-600 transition duration-200"
                                                            >
                                                                <AiOutlinePlus className="mr-1" size={16} />
                                                                Add
                                                            </button>
                                                        </div>
                                                    )}
                                                </div>
                                            </TableCell>
                                            {roleId !== '4' && (
                                                <TableCell>
                                                    <div className="flex gap-2">
                                                        <IconButton onClick={() => handleItemClick(category, 'cat')} >
                                                            <FaRegEdit className="text-blue-500 hover:text-blue-700" />
                                                        </IconButton>
                                                        <IconButton onClick={() => dispatch(categoryDelete(category.id))} >
                                                            <FaTrash className="text-red-600 hover:text-red-800" />
                                                        </IconButton>
                                                    </div>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Pagination */}
                    <TablePagination
                        component="div"
                        count={filteredData.length}
                        page={page}
                        onPageChange={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        rowsPerPageOptions={[10, 25, 50, 100, 'all']}
                        className="bg-gray-50"
                    />
                </Paper>
            </div>

        </>
    );
};

export default Category;