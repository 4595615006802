// import React, { useState, useEffect } from 'react';
// import { Link, useNavigate } from 'react-router-dom';
// import { loginUser } from '../../redux/Slices/auth/authSlice';
// import { useDispatch, useSelector } from 'react-redux';
// import Loader from '../../components/utils/Loader';
// import Logo from '../../assests/logoSvg.svg';

// const Login = () => {
//     const [formData, setFormData] = useState({ email: '', password: '' });
//     const [errors, setErrors] = useState({});
//     const { token, roleId, loading, error } = useSelector((state) => state.auth);
//     const dispatch = useDispatch();
//     const navigate = useNavigate();

//     useEffect(() => {
//         if (token) {
//             let targetPath = '/';

//             switch (roleId) {
//                 case '1': targetPath = '/super-admin'; break;
//                 case '2': targetPath = '/admin'; break;
//                 case '3': targetPath = '/$godown'; break;
//                 case '4': targetPath = '/super-admin'; break;
//                 default: targetPath = '/'; break;
//             }

//             navigate(targetPath);
//         }
//     }, [token, navigate]);

//     const handleChange = (e) => {
//         const { name, value } = e.target;
//         if (name) {
//             setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
//         }
//     };

//     const validate = () => {
//         const newErrors = {};
//         if (!formData.email) newErrors.email = 'Email is required';
//         if (!formData.password) newErrors.password = 'Password is required';
//         return newErrors;
//     };

//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         const validationErrors = validate();

//         if (Object.keys(validationErrors).length === 0) {
//             dispatch(loginUser(formData));
//         } else {
//             setErrors(validationErrors);
//         }
//     };

//     return (
//         <>
//             {loading === true && (
//                 <Loader />
//             )}
//             <div className="flex justify-center items-center h-screen bg-gray-800">
//                 <div className="w-full max-w-md bg-white rounded-lg shadow-lg p-10 pt-0 pb-16 ">
//                     <div className="flex justify-center ">
//                         <img
//                             src={Logo}
//                             alt="Logo"
//                             className="h-[8rem] w-[10rem] "
//                         />
//                     </div>

//                     <form onSubmit={handleSubmit}>
//                         <div className="mb-5">
//                             <label className="block text-sm font-semibold mb-2 text-gray-600" htmlFor="email">
//                                 Email
//                             </label>
//                             <input
//                                 type="email"
//                                 name="email"
//                                 id="email"
//                                 value={formData.email}
//                                 onChange={handleChange}
//                                 className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 hover:shadow-md"
//                                 placeholder="Enter your email"
//                                 required
//                             />
//                         </div>

//                         <div className="mb-6">
//                             <label className="block text-sm font-semibold mb-2 text-gray-600" htmlFor="password">
//                                 Password
//                             </label>
//                             <input
//                                 type="password"
//                                 name="password"
//                                 id="password"
//                                 value={formData.password}
//                                 onChange={handleChange}
//                                 className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-200 hover:shadow-md"
//                                 placeholder="Enter your password"
//                                 required
//                             />
//                         </div>

//                         <button
//                             type="submit"
//                             className="w-full bg-blue-600 text-white py-3 rounded-lg hover:bg-blue-500 transition duration-300 transform hover:scale-105"
//                             disabled={loading}
//                         >
//                             {loading ? 'Logging in...' : 'Login'}
//                         </button>
//                     </form>

//                     {/* {error && <span className="flex mt-6 justify-center text-red-600">{error}</span>} */}
//                 </div>
//             </div>



//         </>
//     );
// };

// export default Login;



import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { loginUser } from '../../redux/Slices/auth/authSlice';
import Loader from '../../components/utils/Loader';
import Logo from '../../assests/logoSvg.svg';

const Login = () => {
    const [formData, setFormData] = useState({ email: '', password: '' });
    const [errors, setErrors] = useState({});
    const { token, roleId, loading } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            let targetPath = '/';
            switch (roleId) {
                case '1': targetPath = '/super-admin'; break;
                case '2': targetPath = '/admin'; break;
                case '3': targetPath = '/godown'; break;
                case '4': targetPath = '/super-admin'; break;
                default: targetPath = '/'; break;
            }
            navigate(targetPath);
        }
    }, [token, navigate]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name) {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const validate = () => {
        const newErrors = {};
        if (!formData.email) newErrors.email = 'Email is required';
        if (!formData.password) newErrors.password = 'Password is required';
        return newErrors;
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const validationErrors = validate();
        if (Object.keys(validationErrors).length === 0) {
            dispatch(loginUser(formData));
        } else {
            setErrors(validationErrors);
        }
    };

    return (
        <>
            {loading && <Loader />}

            {/* Top-left logo */}
            <div className="fixed top-6 left-6 z-10">
                <img src={Logo} alt="Logo" className="h-48 w-auto drop-shadow-lg" />
            </div>

            {/* Background & Login Card */}
            <div className="flex items-center justify-center min-h-screen bg-gradient-to-br from-blue-900 via-purple-900 to-gray-900">
                <div className="backdrop-blur-md bg-white/10 border border-white/20 rounded-2xl shadow-2xl p-10 w-full max-w-md mt-10">
                    <h2 className="text-center text-white text-2xl font-semibold mb-6">
                        Enter credentials to login
                    </h2>

                    <form onSubmit={handleSubmit}>
                        <div className="mb-5">
                            <label htmlFor="email" className="text-white text-sm font-semibold block mb-2">
                                Email
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Enter your email"
                                className="w-full px-4 py-3 rounded-lg border border-white/30 bg-white/10 text-white placeholder-white/70 focus:outline-none focus:ring-2 focus:ring-blue-400 transition"
                                required
                            />
                            {errors.email && <p className="text-red-400 text-xs mt-1">{errors.email}</p>}
                        </div>

                        <div className="mb-6">
                            <label htmlFor="password" className="text-white text-sm font-semibold block mb-2">
                                Password
                            </label>
                            <input
                                type="password"
                                name="password"
                                id="password"
                                value={formData.password}
                                onChange={handleChange}
                                placeholder="Enter your password"
                                className="w-full px-4 py-3 rounded-lg border border-white/30 bg-white/10 text-white placeholder-white/70 focus:outline-none focus:ring-2 focus:ring-blue-400 transition"
                                required
                            />
                            {errors.password && <p className="text-red-400 text-xs mt-1">{errors.password}</p>}
                        </div>

                        <button
                            type="submit"
                            disabled={loading}
                            className="w-full py-3 bg-blue-600 text-white rounded-xl hover:bg-blue-500 hover:scale-[1.02] transition-all duration-300 shadow-lg"
                        >
                            {loading ? 'Logging in...' : 'Login'}
                        </button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default Login;
