import { useEffect, useRef, useState } from "react";
import {
  getProduct,
  addProduct,
  productUpdate,
  productDelete,
  getInventory,
  getAllInventoryDataSuper,
} from "../../redux/Slices/SuperAdmin/productSlice";
import { useDispatch, useSelector } from "react-redux";
import { FaTrash, FaRegEdit } from "react-icons/fa";
import AddProduct from "./AddProduct";
import UpdateProduct from "./UpdateProduct";
import {
  getAdminInventory,
  getAdminProduct,
  getAllInventoryDataAdmin,
} from "../../redux/Slices/Admin/adminProductSlice";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TablePagination,
  IconButton,
  Box,
  Typography,
  LinearProgress,
  Modal,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import * as XLSX from "xlsx";
import Loader from "../../components/utils/Loader";
import { useNavigate } from "react-router-dom";
import {
  bulkProductAdd,
  bulkProductAddAdmin,
} from "../../redux/Slices/BulkProductAdd/bulkAdd";
import { Download as DownloadIcon } from "@mui/icons-material";
import { Upload as UploadIcon } from "@mui/icons-material";
import Topbar from "../../components/Layout/Topbar";
import { getGodown } from "../../redux/Slices/SuperAdmin/inventory/godownSlice";
import { getBrand } from "../../redux/Slices/SuperAdmin/inventory/brandSlice";
import { getCategory } from "../../redux/Slices/SuperAdmin/categorySlice";
import { getFinish } from "../../redux/Slices/SuperAdmin/finishSlice";
import { getSize } from "../../redux/Slices/Measures/sizeSlice";
import ImageGallery from "../../components/utils/ImageGallery";
import { enqueueSnackbar } from "notistack";

const Product = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { admBulkStatus, superBulkStatus, err } = useSelector(
    (state) => state.bulkProduct
  );
  const {
    AllProduct = [],
    AllInventory = [],
    allInvDataSuper = [],
    totalProductsSuper,
    totalInventorySuper,
    updateStatus,
    getStatus,
    invStatus,
    addStatus,
    deleteStatus,
    error,
  } = useSelector((state) => state.product);
  const {
    AdminProducts = [],
    AdminInventory = [],
    allInvDataAdmin = [],
    totalInventoryAdmin,
    adminAddStatus,
    adminGetStatus,
    adminInvStatus,
    adminUpdateStatus,
    adminDeleteStatus,
    totalProductsAdmin,
  } = useSelector((state) => state.adminProduct);

  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(AllProduct);
  const [filteredInventory, setFilteredInventory] = useState(AllInventory);
  const [addForm, setAddForm] = useState(false);
  const [updateForm, setUpdateForm] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState({});
  const { roleId } = useSelector((state) => state.auth);
  const [tabelView, setTabelView] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const fileInputRef = useRef(null);
  const [selectedGodown, setSelectedGodown] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedFinish, setSelectedFinish] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page2, setPage2] = useState(0);
  const [rowsPerPage2, setRowsPerPage2] = useState(10);
  const totalProductItem =
    roleId === "2" ? totalProductsAdmin : totalProductsSuper;
  const totalInventoryItem =
    roleId === "2" ? totalInventoryAdmin : totalInventorySuper;

  const handleGodownChange = (event) => {
    setSelectedGodown(event.target.value);
  };

  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleFinishChange = (event) => {
    setSelectedFinish(event.target.value);
  };

  const { godownData = [] } = useSelector((state) => state.superAdminGodown);
  const { brandData = [] } = useSelector((state) => state.superAdminBrand);
  const { sizeData = [] } = useSelector((state) => state.superAdminSize);
  const { data = [] } = useSelector((state) => state.superAdminCategory);
  const { finishData = [] } = useSelector((state) => state.finish);

  useEffect(() => {
    dispatch(getBrand());
    dispatch(getCategory());
    dispatch(getSize());
    dispatch(getGodown());
    dispatch(getFinish());
  }, []);

  // useEffect(() => {
  //     if (roleId === '2') {
  //         dispatch(getAdminProduct());
  //     } else {
  //         dispatch(getProduct());
  //     }
  // }, [
  //     dispatch,
  //     updateStatus,
  //     addStatus,
  //     deleteStatus,
  //     adminAddStatus,
  //     adminDeleteStatus,
  //     adminUpdateStatus,
  //     admBulkStatus,
  //     superBulkStatus
  // ])

  useEffect(() => {
    if (tabelView) {
      if (roleId === "2") {
        dispatch(
          getAdminProduct({
            page,
            rowsPerPage,
            searchQuery,
            selectedBrand,
            selectedCategory,
            selectedSize,
            selectedFinish,
          })
        );
      } else {
        dispatch(
          getProduct({
            page,
            rowsPerPage,
            searchQuery,
            selectedBrand,
            selectedCategory,
            selectedSize,
            selectedFinish,
          })
        );
      }
    }
  }, [
    dispatch,
    page,
    rowsPerPage,
    searchQuery,
    updateStatus,
    addStatus,
    deleteStatus,
    adminAddStatus,
    adminDeleteStatus,
    adminUpdateStatus,
    admBulkStatus,
    superBulkStatus,
    tabelView,
    selectedBrand,
    selectedCategory,
    selectedSize,
    selectedFinish,
  ]);

  useEffect(() => {
    if (!tabelView) {
      if (roleId === "2") {
        dispatch(
          getAdminInventory({
            page2,
            rowsPerPage2,
            searchQuery,
            selectedGodown,
            selectedBrand,
            selectedCategory,
            selectedSize,
            selectedFinish,
          })
        );
      } else {
        dispatch(
          getInventory({
            page2,
            rowsPerPage2,
            searchQuery,
            selectedGodown,
            selectedBrand,
            selectedCategory,
            selectedSize,
            selectedFinish,
          })
        );
      }
    }
  }, [
    dispatch,
    page2,
    rowsPerPage2,
    searchQuery,
    updateStatus,
    addStatus,
    deleteStatus,
    adminDeleteStatus,
    adminUpdateStatus,
    tabelView,
    selectedGodown,
    selectedBrand,
    selectedCategory,
    selectedSize,
    selectedFinish,
  ]);

  useEffect(() => {
    if (roleId === "2") {
      dispatch(getAllInventoryDataAdmin());
    } else {
      dispatch(getAllInventoryDataSuper());
    }
  }, []);

  useEffect(() => {
    let filteredProducts;

    if (roleId === "1" || roleId === "4") {
      filteredProducts = AllProduct;
    } else {
      filteredProducts = AdminProducts;
    }

    // Apply additional filters
    // const filteredData = filteredProducts.filter((product) => {
    //   const matchesGodown = selectedGodown
    //     ? product.inventories.some(
    //         (inventory) => inventory.godowns_name.name === selectedGodown
    //       )
    //     : true;
    //   const matchesSize = selectedSize ? product.size === selectedSize : true;
    //   const matchesBrand = selectedBrand
    //     ? product.brandname === selectedBrand
    //     : true;
    //   const matchesCategory = selectedCategory
    //     ? product.categoriename === selectedCategory
    //     : true;
    //   const matchesFinish = selectedFinish
    //     ? product.finish === selectedFinish
    //     : true;

    //   return (
    //     matchesGodown &&
    //     matchesBrand &&
    //     matchesCategory &&
    //     matchesFinish &&
    //     matchesSize
    //   );
    // });

    setFilteredData(filteredProducts);
  }, [
    AllProduct,
    page,
    rowsPerPage,
    AdminProducts,
    selectedGodown,
    selectedBrand,
    selectedCategory,
    selectedFinish,
    roleId,
  ]);

  useEffect(() => {
    let filteredInventory;

    if (roleId === "1" || roleId === "4") {
      filteredInventory = AllInventory;
    } else {
      filteredInventory = AdminInventory;
    }

    // Apply additional filters
    // const filteredData = filteredInventory.filter((inv) => {
    //   const matchesGodown = selectedGodown
    //     ? inv.godowns_name?.name === selectedGodown
    //     : true;
    //   const matchesSize = selectedSize
    //     ? inv.product.size === selectedSize
    //     : true;
    //   const matchesBrand = selectedBrand
    //     ? inv.product.brand?.name === selectedBrand
    //     : true;
    //   const matchesCategory = selectedCategory
    //     ? inv.product.category?.name === selectedCategory
    //     : true;
    //   const matchesFinish = selectedFinish
    //     ? inv.product.finish === selectedFinish
    //     : true;
    //   return (
    //     matchesGodown &&
    //     matchesBrand &&
    //     matchesCategory &&
    //     matchesFinish &&
    //     matchesSize
    //   );
    // });

    setFilteredInventory(filteredInventory);
  }, [
    page2,
    rowsPerPage2,
    AdminInventory,
    AllInventory,
    selectedGodown,
    selectedBrand,
    selectedCategory,
    selectedFinish,
    roleId,
  ]);

  const handleItemClick = (product) => {
    setSelectedProduct(product);
    setUpdateForm(true);
  };

  // Handle page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    setRowsPerPage(value === "All" ? totalProductItem : parseInt(value, 10));
    setPage(0);
  };

  const handleChangePage2 = (event, newPage) => {
    setPage2(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage2 = (event) => {
    const value = event.target.value;
    setRowsPerPage2(value === "All" ? totalInventoryItem : parseInt(value, 10));
    setPage2(0);
  };

  const isPending =
    invStatus === "pending" ||
    adminInvStatus === "pending" ||
    getStatus === "pending" ||
    updateStatus === "pending" ||
    addStatus === "pending" ||
    deleteStatus === "pending" ||
    adminAddStatus === "pending" ||
    adminGetStatus === "pending" ||
    adminUpdateStatus === "pending";

  useEffect(() => {
    if (addStatus === "succeeded" || adminAddStatus === "succeeded") {
      setAddForm(false);
    }
    if (adminUpdateStatus === "succeeded" || updateStatus === "succeeded") {
      setUpdateForm(false);
    }
  }, [addStatus, updateStatus, adminAddStatus, adminUpdateStatus]);

  const handleFileChange = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsUploading(true);
      setUploadProgress(0);
      const reader = new FileReader();
      reader.onload = async (event) => {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const productsData = XLSX.utils.sheet_to_json(worksheet);

        // Check for duplicate SKUs in existing products
        const existingProducts = roleId === "1" ? AllProduct : AdminProducts;
        const duplicateSKUs = productsData
          .filter((product) => {
            return existingProducts.some((existingProduct) => {
              // Convert both to strings for comparison
              const existingSku = String(existingProduct.sku).trim();
              const uploadedSku = String(product.sku).trim();
              return existingSku === uploadedSku;
            });
          })
          .map((product) => product.sku);
        // console.log(duplicateSKUs)

        if (duplicateSKUs.length > 0) {
          enqueueSnackbar(
            `The following SKUs already exist in the database: ${duplicateSKUs.join(
              ", "
            )}. Please remove or update these entries.`,
            { variant: "warning", className: "outlined-snackbar" }
          );

          setIsUploading(false);
          e.target.value = null;
          return;
        } else {
          // console.log('passed')
        }

        // Check for duplicate SKUs within the file
        const skusInFile = productsData.map((product) => product.sku);

        // Filtering  empty SKUs
        const validSkusInFile = skusInFile.filter((sku) => sku); // This will keep only non-empty SKUs

        // Checking for duplicate SKUs
        const duplicateSkusInFile = validSkusInFile.filter(
          (sku, index) => validSkusInFile.indexOf(sku) !== index
        );

        if (duplicateSkusInFile.length > 0) {
          enqueueSnackbar(
            `The following SKUs are duplicated in the file: ${[
              ...new Set(duplicateSkusInFile),
            ].join(", ")}. Please remove duplicates.`,
            { variant: "warning", className: "outlined-snackbar" }
          );

          setIsUploading(false);
          e.target.value = null;
          return;
        } else {
          // console.log('cool')
        }

        const filteredData = productsData.map((product) => ({
          id: product.id,
          name: product.name || "",
          sku: product.sku || "",
          size: product.size || "",
          color: product.color || "",
          brand: product.brand || "",
          category_id: product.category_id || 0,
          sub_category: product.sub_category || 0,
          product_in_date: product.product_in_date || "",
          uom: product.uom || "",
          finish: product.finish || "",
          thickness: product.thickness || 0,
          image: product.image || "",
        }));

        const batchSize = 100;
        const batches = Math.ceil(filteredData.length / batchSize);

        for (let i = 0; i < batches; i++) {
          const start = i * batchSize;
          const end = start + batchSize;
          const batch = filteredData.slice(start, end);

          try {
            if (roleId === "1") {
              await dispatch(bulkProductAdd({ bulkProduct: batch }));
            } else {
              await dispatch(bulkProductAddAdmin({ bulkProduct: batch }));
            }

            // status after each batch
            if (superBulkStatus === "failed" || admBulkStatus === "failed") {
              throw new Error(err);
            }

            //  progress
            setUploadProgress(((i + 1) / batches) * 100);
          } catch (error) {
            // console.error(`Error processing batch ${i + 1}:`, error);

            enqueueSnackbar(
              `Something went wrong ${i + 1}: ${JSON.stringify(error.message)}`,
              { variant: "error", className: "outlined-snackbar" }
            );

            setIsUploading(false);
            return;
          }
        }

        if (superBulkStatus === "succeeded" || admBulkStatus === "succeeded") {
          // toast.success('Bulk upload completed successfully!');
        }
        setIsUploading(false);
        e.target.value = null;
      };
      reader.readAsArrayBuffer(file);
    }
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleDownloadExcel = () => {
    let tableData;
    if (tabelView) {
      tableData = filteredData.map((item) => ({
        SKU: item.sku,
        Name: item.name,
        Brand: item.brand?.name,
        Category: item.category?.name,
        SubCategory: item.subcategory?.name,
        Size: item.size,
        UOM: item.uom,
        Color: item.color,
        Finish: item.finish,
        Thickness: item.thickness,
        "Added On": item.product_in_date,
      }));
    } else {
      tableData = filteredInventory.map((inventoryItem, index) => ({
        ...(roleId === "1" && { Godown: inventoryItem.godowns_name?.name }),
        SKU: inventoryItem.product?.sku,
        Name: inventoryItem.product?.name,
        Brand: inventoryItem.product?.brand?.name,
        Category: inventoryItem.product?.category?.name,
        SubCategory: inventoryItem.product?.subcategory?.sname,
        Batch: inventoryItem.batch_id,
        Size: inventoryItem.product?.size,
        UOM: inventoryItem.product?.uom,
        Color: inventoryItem.product?.color,
        Quantity: inventoryItem.qty,
        Finish: inventoryItem.product?.finish,
        Thickness: inventoryItem.product?.thickness,
        MRP: inventoryItem.mrp,
        "Added On": inventoryItem.product_date,
      }));
    }
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "table_data.xlsx");
  };

  const downloadTemplate = () => {
    // Create workbook and worksheet
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.aoa_to_sheet([
      [
        "sku",
        "name",
        "brand",
        "category_id",
        "sub_category",
        "size",
        "uom",
        "color",
        "finish",
        "thickness",
      ],
    ]);

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, "Product Upload Template");

    // Generate Excel file
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Create Blob and download link
    const blob = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "product_upload_template.xlsx";

    // Trigger download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    const products = roleId === "1" ? allInvDataSuper : allInvDataAdmin;

    const totalProducts = products.length;
    const totalInventories = products.reduce(
      (acc, product) => acc + product.inventories?.length,
      0
    );
    const totalBatches = products.reduce((acc, product) => {
      return (
        acc +
        product.inventories?.reduce((acc2, inventory) => {
          return acc2 + (inventory.holdqty ? inventory.holdqty.length : 0);
        }, 0)
      );
    }, 0);
    const availableProducts = products.filter(
      (product) => product.inventories && product?.inventories.length > 0
    );
    const totalAvailableProducts = availableProducts.length;
    const stoppedProduct = products.filter(
      (product) => product.dis_continue === "0"
    );
    const stopCount = stoppedProduct.length;

    // Save values in local storage
    if (roleId === "1") {
      localStorage.setItem("totalProducts", totalProducts);
      localStorage.setItem("totalInventories", totalInventories);
      // localStorage.setItem('totalBatches', totalBatches);
      localStorage.setItem("totalAvailableProducts", totalAvailableProducts);
      localStorage.setItem("stopCount", stopCount);
    } else {
      localStorage.setItem("totalProductsA", totalProducts);
      localStorage.setItem("totalInventoriesA", totalInventories);
      localStorage.setItem("totalBatchesA", totalBatches);
      localStorage.setItem("totalAvailableProductsA", totalAvailableProducts);
      localStorage.setItem("stopCountA", stopCount);
    }
  }, [roleId, allInvDataAdmin, allInvDataSuper]);

  const [filterApplied,setFilterApplied]=useState(false)

  useEffect(() => {
    if (
      selectedBrand !== "" ||
      selectedCategory !== "" ||
      selectedFinish !== "" ||
      selectedSize !== "" ||
      selectedGodown !== ""||
      searchQuery.length!==0
    ){
      setFilterApplied (true);
    }else{
      setFilterApplied(false);
    }
  }, [
    searchQuery,
    selectedGodown,
    selectedBrand,
    selectedCategory,
    selectedFinish,
    selectedSize,
  ]);
console.log(filterApplied)

  return (
    <>
      <Modal
        open={isUploading}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Uploading...
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            Please wait while processing your file.
          </Typography>
          <LinearProgress variant="determinate" value={uploadProgress} />
          {/* <CircularProgress variant="determinate" value={uploadProgress} sx={{ display: 'block', margin: 'auto' }} /> */}
        </Box>
      </Modal>
      {(roleId === "1" || roleId === "4") && <Topbar activeTab={"Products"} />}
      {(isPending&& !filterApplied) && <Loader />}

      {/* {addStock && (
                <div className="fixed z-20 ml-10  inset-0 flex items-center justify-center mt-12 bg-black bg-opacity-50">
                    <div className="bg-white p-6 rounded-lg shadow-lg ">
                        <button
                            className="float-right bg-gray-700 rounded-full p-1 top-2 right-2 text-white hover:text-gray-300"
                            onClick={() => setAddStock(false)}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                        <NewStock />
                    </div>
                </div>
            )} */}
      {addForm && (
        <div className="fixed z-10 inset-0 flex items-center justify-center mt-12 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg ">
            <button
              className="float-right bg-gray-700 rounded-full p-1 top-2 right-2 text-white hover:text-gray-300"
              onClick={() => setAddForm(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <AddProduct />
          </div>
        </div>
      )}
      {updateForm && (
        <div className="fixed inset-0 z-10 flex items-center justify-center mt-12 bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg ">
            <button
              className="float-right bg-gray-700 rounded-full p-1 top-2 right-2 text-white hover:text-gray-300"
              onClick={() => setUpdateForm(false)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
            <UpdateProduct product={selectedProduct} />
          </div>
        </div>
      )}

      <div className="p-3 relative overflow-x-hidden">
        <span className="flex items-center justify-between bg-gradient-to-r pb-14 from-gray-600 to-gray-600 rounded-t-lg">
          <span className="flex flex-col w-full">
            {/* top filters  */}
            <Box display="flex" flexDirection="row" p={2} gap={2}>
              {roleId === "1" && (
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    id="godown-select-label"
                    sx={{ color: "#FFFFFF" }}
                  >
                    Godown
                  </InputLabel>
                  <Select
                    labelId="godown-select-label"
                    value={selectedGodown}
                    onChange={handleGodownChange}
                    label="Godown"
                    sx={{
                      bgcolor: "#90E0",
                      color: "#FFFFFF",
                      "& .MuiSelect-icon": {
                        color: "#FFFFFF",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FFFFFF",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FFFFFF",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFFFF",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#FFFFFF",
                      },
                      "& .MuiSelect-select": {
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>All Godowns</em>
                    </MenuItem>
                    {godownData.map((godown) => (
                      <MenuItem key={godown.id} value={godown.id}>
                        {godown.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <FormControl fullWidth variant="outlined">
                <InputLabel id="brand-select-label" sx={{ color: "#FFFFFF" }}>
                  Brand
                </InputLabel>
                <Select
                  labelId="brand-select-label"
                  value={selectedBrand}
                  onChange={handleBrandChange}
                  label="Brand"
                  sx={{
                    bgcolor: "#90E00",
                    color: "#FFFFFF",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FFFFFF",
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Brands</em>
                  </MenuItem>
                  {brandData.map((brand) => (
                    <MenuItem key={brand.id} value={brand.id}>
                      {brand.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined">
                <InputLabel
                  id="category-select-label"
                  sx={{ color: "#FFFFFF" }}
                >
                  Category
                </InputLabel>
                <Select
                  labelId="category-select-label"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  label="Category"
                  sx={{
                    bgcolor: "#90E0",
                    color: "#FFFFFF",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FFFFFF",
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Categories</em>
                  </MenuItem>
                  {data.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined">
                <InputLabel
                  id="category-select-label"
                  sx={{ color: "#FFFFFF" }}
                >
                  Size
                </InputLabel>
                <Select
                  labelId="size-select-label"
                  value={selectedSize}
                  onChange={handleSizeChange}
                  label="Category"
                  sx={{
                    bgcolor: "#90E0",
                    color: "#FFFFFF",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FFFFFF",
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Sizes</em>
                  </MenuItem>
                  {sizeData.map((size) => (
                    <MenuItem key={size.id} value={size.name}>
                      {size.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined">
                <InputLabel id="finish-select-label" sx={{ color: "#FFFFFF" }}>
                  Finish
                </InputLabel>
                <Select
                  labelId="finish-select-label"
                  value={selectedFinish}
                  onChange={handleFinishChange}
                  label="Finish"
                  sx={{
                    bgcolor: "#90E0",
                    color: "#FFFFFF",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root ```javascript": {
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FFFFFF",
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Finishes</em>
                  </MenuItem>
                  {finishData.map((finish) => (
                    <MenuItem key={finish.id} value={finish.name}>
                      {finish.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <span className="flex items-center justify-between -mt-4">
              <span className="flex">
                <div className="mb-4 m-5">
                  <input
                    type="text"
                    className="rounded w-[20rem] py-2 px-4 border border-gray-400"
                    placeholder="Search by any field"
                    defaultValue={searchQuery}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value.length >= 3 || value.length === 0) {
                        setSearchQuery(value);
                      }
                    }}
                  />
                </div>
              </span>
              <div className="relative w-20 ">
                <Tooltip
                  title={
                    tabelView
                      ? "Switch To Available Product"
                      : "Switch To All Product"
                  }
                  arrow
                >
                  <button
                    className={`w-full h-8 rounded-full p-1 flex items-center justify-between 
                                ${tabelView ? "bg-green-500" : "bg-orange-500"} 
                                transition-colors duration-300 shadow-md`}
                    onClick={() => setTabelView(!tabelView)}
                  >
                    <div
                      className={`w-5 h-5 bg-white rounded-full transition-transform duration-300 
                            shadow-lg ${
                              tabelView ? "translate-x-12" : "translate-x-1"
                            }`}
                    />
                    <span
                      className={`absolute left-5 text-xs transition-all duration-300 text-white font-semibold 
                              ${tabelView ? "opacity-100" : "opacity-0"}`}
                    >
                      PRO
                    </span>

                    <span
                      className={`absolute right-5 text-xs transition-all duration-300 text-white font-semibold 
                             ${tabelView ? "opacity-0" : "opacity-100"}`}
                    >
                      INV
                    </span>
                  </button>
                </Tooltip>
              </div>
              <span className="flex">
                <div className="flex justify-end m-5 mb-4 gap-5">
                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    onClick={handleDownloadExcel}
                    className="bg-blue-600 text-white hover:bg-blue-700 transition-colors"
                  >
                    Download
                  </Button>
                </div>
                {roleId !== "4" && (
                  <>
                    <div className="flex justify-end mt-5 mb-4 gap-5">
                      <span>
                        <label
                          htmlFor="fileInput"
                          className=" font-thin inline-block px-4 py-2 bg-blue-500 text-white rounded cursor-pointer hover:bg-blue-600"
                        >
                          <UploadIcon />
                          BULK ADD
                        </label>
                        <input
                          id="fileInput"
                          type="file"
                          className="hidden"
                          onChange={handleFileChange}
                          ref={fileInputRef}
                        />
                      </span>
                      <Tooltip title="Download Template" arrow>
                        <Button
                          variant="contained"
                          onClick={downloadTemplate}
                          className="bg-blue-600 text-white hover:bg-blue-700 transition-colors"
                        >
                          <DownloadIcon />
                        </Button>
                      </Tooltip>
                    </div>
                    <div className="flex justify-end m-5 mb-4 gap-5">
                      <button
                        className="bg-orange-600 font-thin hover:bg-orange-700 text-white py-2 px-4 rounded shadow-md"
                        onClick={() => setAddForm(true)}
                      >
                        ADD PRODUCT
                      </button>
                    </div>
                  </>
                )}
              </span>
            </span>
          </span>
        </span>

        <div className="overflow-x-auto mt-[-60px]">
          <Paper>
            {tabelView === true ? (
              <>
                <TableContainer>
                  <Table
                    aria-label="customized table"
                    sx={{ width: "100%", tableLayout: "fixed" }}
                  >
                    <TableHead className="bg-gradient-to-r from-gray-600 to-gray-600">
                      <TableRow>
                        <TableCell sx={{ color: "white", width: "80px" }}>
                          SL No.
                        </TableCell>
                        <TableCell sx={{ color: "white", width: "120px" }}>
                          SKU
                        </TableCell>
                        <TableCell sx={{ color: "white", width: "200px" }}>
                          Name
                        </TableCell>
                        <TableCell sx={{ color: "white", width: "150px" }}>
                          Brand
                        </TableCell>
                        <TableCell sx={{ color: "white", width: "150px" }}>
                          Category
                        </TableCell>
                        <TableCell sx={{ color: "white", width: "150px" }}>
                          SubCategory
                        </TableCell>
                        <TableCell sx={{ color: "white", width: "150px" }}>
                          Size
                        </TableCell>
                        <TableCell sx={{ color: "white", width: "100px" }}>
                          UOM
                        </TableCell>
                        <TableCell sx={{ color: "white", width: "150px" }}>
                          Color
                        </TableCell>
                        <TableCell sx={{ color: "white", width: "140px" }}>
                          Finish
                        </TableCell>
                        <TableCell sx={{ color: "white", width: "140px" }}>
                          Thickness
                        </TableCell>
                        <TableCell sx={{ color: "white", width: "120px" }}>
                          Added On
                        </TableCell>
                        <TableCell sx={{ color: "white", width: "60px" }}>
                          Image
                        </TableCell>
                        {roleId !== "4" && (
                          <TableCell sx={{ color: "white", width: "100px" }}>
                            Action
                          </TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredData?.map((item, index) => (
                        <TableRow
                          key={`${item.id}`}
                          className={`${
                            item.dis_continue === "1" ? "" : " bg-red-400 "
                          }${
                            item.dis_continue === "0" ? "" : "hover:bg-gray-200"
                          }`}
                        >
                          <TableCell>
                            {index + 1 + page * rowsPerPage}
                          </TableCell>
                          <TableCell>{item.sku}</TableCell>
                          <TableCell>{item.name}</TableCell>
                          <TableCell>{item.brand?.name}</TableCell>
                          <TableCell>{item.category?.name}</TableCell>
                          <TableCell>{item.subcategory?.name}</TableCell>
                          <TableCell>{item.size}</TableCell>
                          <TableCell>{item.uom}</TableCell>
                          <TableCell>{item.color}</TableCell>
                          <TableCell>{item.finish}</TableCell>
                          <TableCell>{item.thickness}</TableCell>
                          <TableCell>
                            {new Date(item.product_in_date)
                              ?.toLocaleDateString("en-GB")
                              .split("/")
                              .join("/")}
                          </TableCell>
                          <TableCell>
                            <ImageGallery
                              imageUrl={item.ImageUrl}
                              imageUrl2={item.ImageUrl2}
                              imageUrl3={item.ImageUrl3}
                              imageUrl4={item.ImageUrl4}
                            />
                          </TableCell>
                          {roleId !== "4" && (
                            <TableCell>
                              <span className="flex h-1 w-1">
                                <IconButton
                                  onClick={() => handleItemClick(item)}
                                  aria-label="edit"
                                >
                                  <FaRegEdit className="text-blue-700" />
                                </IconButton>
                                {roleId === "1" && (
                                  <IconButton
                                    onClick={() =>
                                      dispatch(productDelete(item.id))
                                    }
                                    aria-label="delete"
                                  >
                                    <FaTrash className="text-red-600" />
                                  </IconButton>
                                )}
                              </span>
                            </TableCell>
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  component="div"
                  count={totalProductItem}
                  page={page}
                  onPageChange={handleChangePage}
                  rowsPerPage={rowsPerPage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  rowsPerPageOptions={[10, 25, 50, 100, "All"]}
                />
              </>
            ) : (
              <>
                <>
                  <>
                    <TableContainer>
                      <Table
                        aria-label="customized table"
                        sx={{ width: "100%", tableLayout: "fixed" }}
                      >
                        <TableHead className="bg-gradient-to-r from-gray-600 to-gray-600">
                          <TableRow>
                            <TableCell sx={{ color: "white", width: "80px" }}>
                              SL No.
                            </TableCell>
                            <TableCell sx={{ color: "white", width: "120px" }}>
                              SKU
                            </TableCell>
                            {(roleId === "1" || roleId === "4") && (
                              <TableCell
                                sx={{ color: "white", width: "200px" }}
                              >
                                Godown
                              </TableCell>
                            )}
                            <TableCell sx={{ color: "white", width: "200px" }}>
                              Name
                            </TableCell>
                            <TableCell sx={{ color: "white", width: "150px" }}>
                              Brand
                            </TableCell>
                            <TableCell sx={{ color: "white", width: "150px" }}>
                              Category
                            </TableCell>
                            <TableCell sx={{ color: "white", width: "150px" }}>
                              SubCategory
                            </TableCell>
                            <TableCell sx={{ color: "white", width: "150px" }}>
                              Batch
                            </TableCell>
                            <TableCell sx={{ color: "white", width: "150px" }}>
                              Size
                            </TableCell>
                            <TableCell sx={{ color: "white", width: "150px" }}>
                              Color
                            </TableCell>
                            <TableCell sx={{ color: "white", width: "100px" }}>
                              Quantity
                            </TableCell>
                            <TableCell sx={{ color: "white", width: "100px" }}>
                              UOM
                            </TableCell>
                            <TableCell sx={{ color: "white", width: "140px" }}>
                              Finish
                            </TableCell>
                            <TableCell sx={{ color: "white", width: "140px" }}>
                              Thickness
                            </TableCell>
                            <TableCell sx={{ color: "white", width: "100px" }}>
                              MRP
                            </TableCell>
                            <TableCell sx={{ color: "white", width: "120px" }}>
                              Added On
                            </TableCell>
                            <TableCell sx={{ color: "white", width: "90px" }}>
                              Image
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredInventory?.map((inventoryItem, index) => (
                            <TableRow key={`${inventoryItem.id}`} hover>
                              <TableCell>
                                {page2 * rowsPerPage2 + index + 1}
                              </TableCell>
                              <TableCell>
                                {inventoryItem.product?.sku}
                              </TableCell>
                              {(roleId === "1" || roleId === "4") && (
                                <TableCell>
                                  {inventoryItem.godowns_name?.name}
                                </TableCell>
                              )}
                              <TableCell>
                                {inventoryItem.product?.name}
                              </TableCell>
                              <TableCell>
                                {inventoryItem.product?.brand?.name}
                              </TableCell>
                              <TableCell>
                                {inventoryItem.product?.category?.name}
                              </TableCell>
                              <TableCell>
                                {inventoryItem.product?.subcategory?.name}
                              </TableCell>
                              <TableCell>{inventoryItem.batch_id}</TableCell>
                              <TableCell>
                                {inventoryItem.product?.size}
                              </TableCell>
                              <TableCell>
                                {inventoryItem.product?.color}
                              </TableCell>
                              <TableCell>{inventoryItem.qty}</TableCell>
                              <TableCell>
                                {inventoryItem.product?.uom}
                              </TableCell>
                              <TableCell>
                                {inventoryItem.product?.finish}
                              </TableCell>
                              <TableCell>
                                {inventoryItem.product?.thickness}
                              </TableCell>
                              <TableCell>{inventoryItem.mrp}</TableCell>
                              <TableCell>
                                {inventoryItem.product_date
                                  ? new Date(inventoryItem.product_date)
                                      .toLocaleDateString("en-GB")
                                      .split("/")
                                      .join("/")
                                  : "NA"}
                              </TableCell>
                              <TableCell>
                                <ImageGallery
                                  imageUrl={inventoryItem?.product?.ImageUrl}
                                  imageUrl2={inventoryItem?.product?.ImageUrl2}
                                  imageUrl3={inventoryItem?.product?.ImageUrl3}
                                  imageUrl4={inventoryItem?.product?.ImageUrl4}
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <TablePagination
                      component="div"
                      count={totalInventoryItem}
                      page={page2}
                      onPageChange={handleChangePage2}
                      rowsPerPage={rowsPerPage2}
                      onRowsPerPageChange={handleChangeRowsPerPage2}
                      rowsPerPageOptions={[10, 25, 50, 100, "All"]}
                    />
                  </>
                </>
              </>
            )}
          </Paper>
        </div>
      </div>
    </>
  );
};

export default Product;
