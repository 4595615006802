import React, { useEffect } from "react";
import Transaction from "../../Report/Transaction";
import AdminTopbar from "../Layout/AdminTopbar";

import { useDispatch, useSelector } from "react-redux";
import {
  getDamaged,
  getReturned,
  getStockInAdmin,
  getStockInSuper,
  getStockOutAdmin,
  getStockOutSuper,
} from "../../../redux/Slices/Report/reportSlice";
import Loader from "../../../components/utils/Loader";

const AdminTransaction = () => {
  const dispatch = useDispatch();
  const { stockInDataAdmin = [], stockOutDataAdmin = [],returnData = [],damageData = [], status } = useSelector(
    (state) => state.report
  );  

  useEffect(() => {
    dispatch(getStockInAdmin());
    dispatch(getStockOutAdmin());
   dispatch(getReturned()); 
    dispatch(getDamaged());
  }, []);


  const  flattenStockData = (stockInData, stockOutData,returnData,damageData)=> {

    const flatStockIn = stockInData?.map(item => ({
        id: item?.id,
        sku: item?.product?.sku,
        name: item?.product?.name,
        brand: item?.product?.brand?.name,
        category: item?.product?.category?.name,
        subcategory: item?.product?.subcategory?.name,
        size: item?.product?.size,
        color: item?.product?.color,
        finish: item?.product?.finish,
        thickness: item?.product?.thickness,
        mrp: item?.mrp,
        date: item?.product_date,
        stockInQty: item?.total_qty,
        stockOutQty:0,
        returnQty:0,
        damageQty:0,
        uom: item?.product?.uom,
        ImageUrl: item?.product?.ImageUrl,
        batch: item?.batch_id,
        type: "StockIn"
    }));

    const flatStockOut = stockOutData?.map(item => ({
        id: item?.id,
        sku: item?.product?.sku,
        name: item?.product?.name,
        brand: item?.product?.brand?.name,
        category: item?.product?.category?.name,
        subcategory: item?.product?.subcategory?.name,
        size: item?.product?.size,
        color: item?.product?.color,
        finish: item?.product?.finish,
        thickness: item?.product?.thickness,
        mrp: item?.stock_in_reports?.mrp,
        date: item?.product_date,
        stockInQty: 0,
        stockOutQty: item?.previous_qty,
        returnQty:0,
        damageQty:0,
        uom: item?.product?.uom,
        ImageUrl: item?.product?.ImageUrl,
        batch: item?.batch,
        type: "StockOut"
    }));

    const flatReturn = returnData?.map((item) => ({
      id: item?.id,
      sku: item?.inventory?.product?.sku_code,
      name: item?.inventory?.product?.name || "N/A",
      brand: item?.inventory?.product?.brand?.name || "N/A",
      category: item?.inventory?.product?.category?.name || "N/A",
      subcategory: item?.inventory?.product?.subcategory?.name || "N/A",
      size: item?.inventory?.product?.size || "N/A",
      color: item?.inventory?.product?.color || "N/A",
      finish: item?.inventory?.product?.finish || "N/A",
      thickness: item?.inventory?.product?.thickness || "N/A",
      mrp: item?.inventory?.mrp || "N/A",
      date: item?.product_date,
      stockInQty: 0,
      stockOutQty: 0,
      returnQty: item?.qty,
      damageQty: 0,
      uom: item?.inventory?.product?.uom || "N/A",
      ImageUrl: item?.inventory?.product?.ImageUrl || "N/A",
      batch: item?.inventory?.batch_id,
      type: "Return",
  }));
  
  const flatDamage = damageData?.map((item) => ({
      id: item?.id,
      sku: item?.inventory?.product?.sku_code,
      name: item?.inventory?.product?.name || "N/A",
      brand: item?.inventory?.product?.brand?.name || "N/A",
      category: item?.inventory?.product?.category?.name || "N/A",
      subcategory: item?.inventory?.product?.subcategory?.name || "N/A",
      size: item?.inventory?.product?.size || "N/A",
      color: item?.inventory?.product?.color || "N/A",
      finish: item?.inventory?.product?.finish || "N/A",
      thickness: item?.inventory?.product?.thickness || "N/A",
      mrp: item?.inventory?.mrp || "N/A",
      date: item?.product_date,
      stockInQty: 0,
      stockOutQty: 0,
      returnQty: 0,
      damageQty: item?.qty,
      uom: item?.inventory?.product?.uom || "N/A",
      ImageUrl: item?.inventory?.product?.ImageUrl || "N/A",
      batch: item?.inventory?.batch_id,
      type: "Damage",
  }));
  

  const mergedData = [...flatStockIn, ...flatStockOut, ...flatReturn, ...flatDamage].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

    return mergedData;
}

const mergedStockData = flattenStockData(stockInDataAdmin, stockOutDataAdmin,returnData,damageData);
console.table(mergedStockData.slice(0,11))
  return (
    <>
     {status==='pending'&& <Loader/>}
      <AdminTopbar activeTab={"transaction"} />
      <Transaction  propData={mergedStockData}/>
    </>
  );
};

export default AdminTransaction;
