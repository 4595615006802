import React, { useEffect, useState } from 'react';
import { getGodown, addGodown, updateGodown, deleteGodown } from '../../../../redux/Slices/SuperAdmin/inventory/godownSlice';
import { useDispatch, useSelector } from 'react-redux';
import TableView from '../../../../components/utils/TableView';
import Topbar from '../../../../components/Layout/Topbar';

const Godown = () => {

    const { godownData = [], updateStatus, getStatus, addStatus, deleteStatus } = useSelector(state => state.superAdminGodown);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getGodown());
    }, [dispatch, updateStatus, addStatus, deleteStatus]);

    return (
        <>
            <Topbar activeTab={'godowns'} />
            <TableView
                data={godownData}
                deleteThunk={deleteGodown}
                addThunk={addGodown}
                updateThunk={updateGodown}
                addStatus={addStatus}
                deleteStatus={deleteStatus}
                updateStatus={updateStatus}
                getStatus={getStatus}
            />

        </>
    );
};

export default Godown;