import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Alert, Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BrandGraph = ({ data = [],title }) => {

  // // Ensure data is an array
  // if (!Array.isArray(data) || data.length === 0) {
  //   return (
  //     <Box className="flex justify-center items-center mt-6">
  //       <Alert severity="info" icon={<InfoIcon />} className="text-gray-600">
  //         No data available to display.
  //       </Alert>
  //     </Box>
  //   );
  // }

  // Extract brand names & product counts
  const labels = data.map((item) => item.name);
  const productCounts = data.map((item) => item.product_count);

  const barChartData = {
    labels,
    datasets: [
      {
        label: "Product Count",
        data: productCounts,
        backgroundColor: [
          "rgba(255, 99, 132, 0.7)",
          "rgba(54, 162, 235, 0.7)",
          "rgba(255, 206, 86, 0.7)",
          "rgba(75, 192, 192, 0.7)",
          "rgba(153, 102, 255, 0.7)",
          "rgba(255, 159, 64, 0.7)",
        ],
        borderColor: [
          "rgba(255, 99, 132, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        labels: {
          font: { size: 14 },
          color: "#333",
        },
      },
      tooltip: {
        backgroundColor: "rgba(0,0,0,0.8)",
        titleFont: { size: 14 },
        bodyFont: { size: 12 },
      },
    },
    scales: {
      x: {
        grid: { display: false },
        ticks: { font: { size: 12 }, color: "#333" },
      },
      y: {
        grid: { color: "rgba(200,200,200,0.3)" },
        ticks: { font: { size: 12 }, color: "#333" },
      },
    },
  };

  return (
    <div className="mt-10">
      <div className="bg-white p-6 rounded-lg shadow-lg border border-gray-300">
      <h2 className="text-2xl font-semibold text-gray-700 mb-4"> <DoubleArrowIcon className="mr-2"/>{title}</h2>
        <div>
        <Bar data={barChartData} options={options} height={400} />
      </div>
      </div>
    </div>
  );
};

export default BrandGraph;
