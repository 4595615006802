import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from '../../apiConfig';
import { enqueueSnackbar } from "notistack";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const getUom = createAsyncThunk(
    "super_admin/getUom",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/uom`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
                        enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });
            
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const addUom = createAsyncThunk(
    "addUom",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/uom/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            enqueueSnackbar("Added succesfully!", { variant: "success" ,  className: 'outlined-snackbar'});
            const data = await response.json();
            return data;
        } catch (error) {
                        enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });
            
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const updateUom = createAsyncThunk(
    "updateUom",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/uom/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }
            enqueueSnackbar("Updated succesfully!", { variant: "success" ,  className: 'outlined-snackbar'});

            const data = await response.json();
            return data;
        } catch (error) {
                        enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });
            
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const deleteUom = createAsyncThunk(
    "deleteUom",
    async (id, { rejectWithValue }) => {
        console.log("Delete thunk called with id:", id);

        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/uom/delete/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            enqueueSnackbar("Deleted succesfully!", { variant: "success" ,  className: 'outlined-snackbar'});

            const data = await response.json();
            return data;
        } catch (error) {
                        enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });
            
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);


const superAdminUomsSlice = createSlice({
    name: 'uom',
    initialState: {
        uomData: [],
        getStatus: 'idle',
        addStatus: 'idle',
        updateStatus: 'idle',
        deleteStatus: 'idle',
        error: null,
        uom: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUom.pending, (state) => {
                state.getStatus = 'pending';
            })
            .addCase(getUom.fulfilled, (state, action) => {
                state.getStatus = 'succeeded';
                state.uomData = action.payload.data;
            })
            .addCase(getUom.rejected, (state, action) => {
                state.getStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addUom.pending, (state) => {
                state.addStatus = 'pending';
            })
            .addCase(addUom.fulfilled, (state, action) => {
                state.addStatus = 'succeeded';
            })
            .addCase(addUom.rejected, (state, action) => {
                state.addStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(updateUom.pending, (state) => {
                state.updateStatus = 'pending';
            })
            .addCase(updateUom.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';
            })
            .addCase(updateUom.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(deleteUom.pending, (state) => {
                state.deleteStatus = 'pending';
            })
            .addCase(deleteUom.fulfilled, (state, action) => {
                state.deleteStatus = 'succeeded';
            })
            .addCase(deleteUom.rejected, (state, action) => {
                state.deleteStatus = 'failed';
                state.error = action.payload;
            });
    }
});

export default superAdminUomsSlice.reducer;
