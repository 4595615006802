import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";
import {
  Table,
  TextField,
  Button,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import {
  stockUpdate,
  superStockOut,
} from "../../../redux/Slices/Admin/Stocks/stocksSlice";
import { AiOutlineClose } from "react-icons/ai";
import { getAllInventoryDataSuper } from "../../../redux/Slices/SuperAdmin/productSlice";
import Topbar from "../../../components/Layout/Topbar";
import Loader from "../../../components/utils/Loader";
import { getGodown } from "../../../redux/Slices/SuperAdmin/inventory/godownSlice";
import { enqueueSnackbar } from "notistack";

const SuperStockOut = () => {
  const dispatch = useDispatch();
  const { allInvDataSuper = [], otherStatSuper } = useSelector(
    (state) => state.product
  );
  const { superOutStatus } = useSelector((state) => state.stock);
  const [searchInput, setSearchInput] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [cart, setCart] = useState([]);
  const [stockOutItem, setStockOutItem] = useState([]);

  useEffect(() => {
    dispatch(getAllInventoryDataSuper());
    dispatch(getGodown());
  }, [dispatch]);

  const loadOptions = async (inputValue) => {
    setStockOutItem([]);
    setSearchInput(inputValue);

    const uniqueProducts = allInvDataSuper.reduce((acc, product) => {
      if (!acc.some((p) => p.name === product.name)) {
        acc.push({
          label: `${product.name} (SKU: ${product.sku})`,
          value: product.name,
          sku: product.sku,
        });
      }
      return acc;
    }, []);

    return uniqueProducts.filter(
      (product) =>
        product.label.toLowerCase().includes(inputValue.toLowerCase()) ||
        product.sku.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const handleSelectChange = (selectedOption) => {
    setStockOutItem([]);
    setSelectedProduct(selectedOption);
    if (selectedOption) {
      const relatedProducts = allInvDataSuper.filter(
        (product) => product.name === selectedOption.value
      );
      setFilteredProducts(relatedProducts);
    } else {
      setFilteredProducts([]);
    }
  };

  const handleRemarksChange = (productId, inventoryId, value) => {
    setCart((prevCart) => {
      return prevCart.map((item) =>
        item.productId === productId && item.inventoryId === inventoryId
          ? { ...item, remarks: value }
          : item
      );
    });
  };

  const handleQuantityChange = (product, inventory, quantity) => {
    setCart((prevCart) => {
      const validQuantity = inventory.qty - inventory.total_hold_qty;
      const existingProduct = prevCart.find(
        (item) =>
          item.productId === product.id && item.inventoryId === inventory.id
      );
      if (quantity <= 0 && quantity !== "") {
        enqueueSnackbar("Quantity must be greater than 0", {
          variant: "warning",
          className: "outlined-snackbar",
        });

        return prevCart;
      }
      if (
        quantity.length > 0 &&
        parseFloat(quantity) >
          parseFloat(inventory.qty - inventory.total_hold_qty)
      ) {
        enqueueSnackbar("Quantity cannot exceed the available inventory", {
          variant: "warning",
          className: "outlined-snackbar",
        });

        return prevCart;
      }
      if (existingProduct) {
        return prevCart.map((item) =>
          item.productId === product.id && item.inventoryId === inventory.id
            ? { ...item, quantity: parseFloat(quantity) }
            : item
        );
      }
      return [
        ...prevCart,
        {
          validQty: validQuantity,
          productId: product.id,
          inventoryId: inventory.id,
          quantity: quantity,
          name: product.name,
          sku: product.sku,
          uom: product.uom,
          batch: inventory.batch_id,
        },
      ];
    });
  };

  const updateCartQuantity = (item, newQuantity) => {
    setCart((prevCart) => {
      const validQuantity = item.validQty;
      if (newQuantity <= 0 && newQuantity !== "") {
        enqueueSnackbar("Quantity must be greater than 0", {
          variant: "warning",
          className: "outlined-snackbar",
        });

        return prevCart;
      }
      if (newQuantity.length > 1 && parseFloat(newQuantity) > validQuantity) {
        enqueueSnackbar("Quantity cannot exceed the available inventory", {
          variant: "warning",
          className: "outlined-snackbar",
        });

        return prevCart;
      }
      return prevCart.map((cartItem) =>
        cartItem.productId === item.productId &&
        cartItem.inventoryId === item.inventoryId
          ? { ...cartItem, quantity: Math.max(0, parseFloat(newQuantity, 10)) }
          : cartItem
      );
    });
  };

  const handleBatchChange = (index, fieldName, value) => {
    setCart((prevCart) => {
      return prevCart.map((cartItem, i) =>
        i === index ? { ...cartItem, [fieldName]: value } : cartItem
      );
    });
  };

  const handleRemoveFromCart = (productId, inventoryId) => {
    setCart((prevCart) =>
      prevCart.filter(
        (item) =>
          !(item.productId === productId && item.inventoryId === inventoryId)
      )
    );
  };

  useEffect(() => {
    if (superOutStatus === "succeeded") {
      setCart([]);
      setSelectedProduct(null);
      setFilteredProducts([]);
    }
  }, [superOutStatus]);
  const handleSubmit = () => {
    const updateProducts = cart
      .filter(
        (item) => item.quantity && item.quantity !== 0 && item.quantity !== ""
      )
      .map((item) => ({
        product_id: item.productId,
        report_id: allInvDataSuper?.find(product => product.sku === item.sku)
        ?.stock_in_reports?.find(report => report.sku_code === item.sku)?.id || null,
        
        sku: item.sku,
        name: item.name,
        batch: item.batch,
        id: item.inventoryId,
        qty: item.quantity,
        remarks: item.remarks ? item.remarks : "NA",
        product_date: item.product_date
          ? item.product_date
          : new Date().toISOString().split("T")[0],
      }));


    if (updateProducts.length === 0) {
      enqueueSnackbar("Please ensure items are entered correctly", {
        variant: "warning",
        className: "outlined-snackbar",
      });

      return;
    }

    dispatch(superStockOut({ updateProducts }));
    setStockOutItem(updateProducts);
  };

  const isPending =
    superOutStatus === "pending" || otherStatSuper === "pending";

  return (
    <>
      <Topbar activeTab={"stock out"} />
      <div
        className={`p-3  text-sm min-h-screen ${
          filteredProducts.length > 0 || cart.length > 0 ? "" : ""
        }`}
      >
        {isPending && <Loader />}
        <div className="bg-gradient-to-r from-gray-600 to-gray-600 rounded-t-lg shadow-lg p-6">
          <h2 className="font-semibold text-xl mb-4 text-white">
            Available Products
          </h2>
          <AsyncSelect
            cacheOptions
            loadOptions={loadOptions}
            onChange={handleSelectChange}
            placeholder="Search for products..."
            isClearable
            className="mb-4"
          />
        </div>

        {selectedProduct ? (
          <div className="overflow-x-auto">
            <table className="table-auto bg-white w-full text-left">
              <thead className="text-white bg-gradient-to-r from-gray-600 to-gray-600 ">
                <tr className="w-[]">
                  <th className="px-4 py-2">Godown</th>
                  <th className="px-4 py-2">SKU</th>
                  <th className="px-4 py-2">Name</th>
                  <th className="px-4 py-2">Batch</th>
                  <th className="px-4 py-2">QTY AVL</th>
                  <th className="px-4 py-2">UOM</th>
                  <th className="px-6 py-4">MRP</th>
                  <th className="px-4 py-2"> Stock Out</th>
                  <th className="px-4 py-2">Remarks</th>
                  <th className="px-6 py-4">Size</th>
                  <th className="px-6 py-4">Finish</th>
                  <th className="px-6 py-4">Brand</th>
                  <th className="px-6 py-4">Category</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                <>
                  {filteredProducts.map((product) =>
                    product.inventories?.map((inventory) => (
                      <tr
                        key={`${product.id}-${inventory.id}`}
                        className={
                          inventory.qty === "0" ||
                          inventory.qty === null ||
                          inventory.qty === ""
                            ? "bg-gray-100 opacity-50"
                            : ""
                        }
                      >
                        <td className="px-4 py-2 min-w-[130px]">
                          {inventory.godowns_name?.name}
                        </td>
                        <td className="px-4 py-2 min-w-[100px]">
                          {product.sku}
                        </td>
                        <td className="px-4 py-2 min-w-[220px]">
                          {product.name}
                        </td>
                        <td className="px-4 py-2 min-w-[80px]">
                          {inventory.batch_id}
                        </td>
                        <td className="px-4 py-2 min-w-[106px]">
                          {inventory.qty - inventory.total_hold_qty}{" "}
                        </td>
                        <td className="px-6 py-4 min-w-[80px]">
                          {product.uom}
                        </td>
                        <td className="px-6 py-4 min-w-[80px]">
                          {inventory.mrp}
                        </td>
                        <td className="px-4 py-2 ">
                          <TextField
                            type="number"
                            value={
                              cart.find(
                                (item) =>
                                  item.productId === product.id &&
                                  item.inventoryId === inventory.id
                              )?.quantity || ""
                            }
                            onChange={(e) =>
                              handleQuantityChange(
                                product,
                                inventory,
                                e.target.value
                              )
                            }
                            className="w-24 border border-gray-300 rounded-lg p-2"
                            disabled={
                              inventory.qty === "0" || inventory.qty === null
                            }
                          />
                        </td>
                        <td className="px-4 py-2">
                          <TextField
                            id="outlined-multiline-static"
                            label="Remarks..."
                            multiline
                            variant="outlined"
                            className="w-48 border border-gray-300 rounded-lg p-2"
                            onChange={(e) =>
                              handleRemarksChange(
                                product.id,
                                inventory.id,
                                e.target.value
                              )
                            }
                          />
                        </td>
                        <td className="px-6 py-4 min-w-[80px]">
                          {product.size}
                        </td>
                        <td className="px-6 py-4 min-w-[80px]">
                          {product.finish}
                        </td>
                        <td className="px-6 py-4 min-w-[100px]">
                          {product.brandname}
                        </td>
                        <td className="px-6 py-4 min-w-[140px]">
                          {product.categoriename}
                        </td>
                      </tr>
                    ))
                  )}
                </>
              </tbody>
            </table>
          </div>
        ) : (
          <p className=" text-center">No products selected</p>
        )}

        {cart.length > 0 && (
          <div className="bg-gray-100 mt-12 p-6 rounded-xl shadow-xl">
            <h3 className="font-bold mb-6 text-gray-800">Selected Products</h3>
            <div className="overflow-x-auto">
              <table className="table-auto w-full text-left border-collapse">
                <thead className="bg-gradient-to-r from-gray-600 to-gray-600 text-white">
                  <tr>
                    <th className="px-6 py-4">SKU</th>
                    <th className="px-6 py-4">Name</th>
                    <th className="px-6 py-4">Batch</th>
                    <th className="px-6 py-4">Stock Out</th>
                    <th className="px-6 py-4">UOM</th>
                    <th className="px-6 py-4">Date</th>
                    <th className="px-6 py-4">Action</th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-300 bg-white">
                  {cart.map((item, index) => (
                    <tr
                      key={`${item.productId}-${item.inventoryId}`}
                      className={`${
                        index % 2 === 0 ? "bg-gray-50" : "bg-white"
                      }`}
                    >
                      <td className="px-6 py-1">{item.sku}</td>
                      <td className="px-6 py-1">{item.name}</td>
                      <td className="px-6 py-1">{item.batch}</td>
                      <td className="px-6 py-1">
                        <TextField
                          type="number"
                          value={item.quantity}
                          onChange={(e) =>
                            updateCartQuantity(item, e.target.value)
                          }
                          className="w-24 border border-gray-300 rounded-lg "
                          // readonly
                        />
                      </td>
                      <td className="px-6 py-1">{item.uom}</td>
                      <td className="px-6 py-1">
                        <TextField
                          type="date"
                          value={item.product_date}
                          onChange={(e) =>
                            handleBatchChange(
                              index,
                              "product_date",
                              e.target.value
                            )
                          }
                          className="w-36 border border-gray-300 rounded-lg p-2"
                        />
                      </td>
                      <td className="px-6 py-1">
                        <IconButton
                          color="secondary"
                          onClick={() =>
                            handleRemoveFromCart(
                              item.productId,
                              item.inventoryId
                            )
                          }
                          className="hover:bg-red-100  rounded-full"
                        >
                          <AiOutlineClose
                            size={22}
                            className="bg-red-200 h-10 w-10 font-thin rounded-full p-3 text-black"
                          />
                        </IconButton>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="flex justify-center mt-8">
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                className="py-3 px-6"
                disabled={isPending}
              >
                {isPending ? "Processing..." : "Stock Out"}
              </Button>
            </div>
          </div>
        )}
        {stockOutItem.length > 0 && (
          <div className="bg-gray-100 p-8 rounded-xl shadow-xl mt-6">
            <h3 className="font-bold mb-6 text-gray-800"> Stocks Removed</h3>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>SKU</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Batch</TableCell>
                    <TableCell>Quantity removed</TableCell>
                    <TableCell>Remarks</TableCell>
                    <TableCell>Out Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {stockOutItem.map((product, index) => (
                    <TableRow key={index}>
                      <TableCell>{product.sku}</TableCell>
                      <TableCell>{product.name}</TableCell>
                      <TableCell>{product.batch}</TableCell>
                      <TableCell>{product.qty}</TableCell>
                      <TableCell>{product.remarks}</TableCell>
                      <TableCell className="text-center py-1">
                        {new Date(product.product_date).toLocaleDateString(
                          "en-GB",
                          {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit",
                          }
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </>
  );
};

export default SuperStockOut;
