import React, { useEffect } from "react";
import Topbar from "../../../components/Layout/Topbar";
import Transaction from "../../Report/Transaction";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../components/utils/Loader";
import {
  getDamagedSuper,
  getReturnedSuper,
  getStockInSuper,
  getStockOutSuper,
} from "../../../redux/Slices/Report/reportSlice";
const SuperTransaction = () => {
  const dispatch = useDispatch();
  const {
    stockInDataSuper = [],
    stockOutDataSuper = [],
    returnDataSuper = [],
    damageDataSuper = [],
    status,
  } = useSelector((state) => state.report);

  useEffect(() => {
    dispatch(getStockInSuper());
    dispatch(getStockOutSuper());
    dispatch(getReturnedSuper());
    dispatch(getDamagedSuper());
  }, []);

  const dates = stockInDataSuper.map((product) => product.product_date);

  const latestDate = new Date(
    Math.max(...dates.filter((date) => !isNaN(Date.parse(date))))
  );
  const oldestDate = new Date(
    Math.min(...dates.filter((date) => !isNaN(Date.parse(date))))
  );

  console.log(`Latest Date: ${latestDate.toLocaleDateString()}`);
  console.log(`Oldest Date: ${oldestDate.toLocaleDateString()}`);
  
  const flattenStockData = (stockInData, stockOutData,returnData,damageData) => {
    const flatStockIn = stockInData?.map((item) => ({
      id: item?.id,
      godown: item?.godowns_name?.name || "N/A",
      sku: item?.product?.sku,
      name: item?.product?.name,
      brand: item?.product?.brand?.name,
      category: item?.product?.category?.name,
      subcategory: item?.product?.subcategory?.name,
      size: item?.product?.size,
      color: item?.product?.color,
      finish: item?.product?.finish,
      thickness: item?.product?.thickness,
      mrp: item?.mrp,
      date: item?.product_date,
      stockInQty: item?.total_qty,
      stockOutQty: 0,
      returnQty:0,
      damageQty:0,
      uom: item?.product?.uom,
      ImageUrl: item?.product?.ImageUrl,
      batch: item?.batch_id,
      type: "StockIn",
    }));

    const flatStockOut = stockOutData?.map((item) => ({
      id: item?.id,
      godown: item?.stock_in_reports?.godowns_name?.name || "N/A",
      sku: item?.product?.sku,
      name: item?.product?.name,
      brand: item?.product?.brand?.name,
      category: item?.product?.category?.name,
      subcategory: item?.product?.subcategory?.name,
      size: item?.product?.size,
      color: item?.product?.color,
      finish: item?.product?.finish,
      thickness: item?.product?.thickness,
      mrp: item?.stock_in_reports?.mrp,
      date: item?.product_date,
      stockInQty: 0,
      stockOutQty: item?.previous_qty,
      returnQty:0,
      damageQty:0,
      uom: item?.product?.uom,
      ImageUrl: item?.product?.ImageUrl,
      batch: item?.batch,
      type: "StockOut",
    }));

    const flatReturn = returnData?.map((item) => ({
        id: item?.id,
        godown: item?.godownsname || "N/A",
        sku: item?.inventory?.product?.sku,
        name: item?.inventory?.product?.name || "N/A",
        brand: item?.inventory?.product?.brand?.name || "N/A",
        category: item?.inventory?.product?.category?.name || "N/A",
        subcategory: item?.inventory?.product?.subcategory?.name || "N/A",
        size: item?.inventory?.product?.size || "N/A",
        color: item?.inventory?.product?.color || "N/A",
        finish: item?.inventory?.product?.finish || "N/A",
        thickness: item?.inventory?.product?.thickness || "N/A",
        mrp: item?.inventory?.mrp || "N/A",
        date: item?.product_date,
        stockInQty: 0,
        stockOutQty: 0,
        returnQty: item?.qty,
        damageQty: 0,
        uom: item?.inventory?.product?.uom || "N/A",
        ImageUrl: item?.inventory?.product?.ImageUrl || "N/A",
        batch: item?.inventory?.batch_id,
        type: "Return",
    }));
    
    const flatDamage = damageData?.map((item) => ({
        id: item?.id,
        godown: item?.godownsname || "N/A",
        sku: item?.inventory?.product?.sku,
        name: item?.inventory?.product?.name || "N/A",
        brand: item?.inventory?.product?.brand?.name || "N/A",
        category: item?.inventory?.product?.category?.name || "N/A",
        subcategory: item?.inventory?.product?.subcategory?.name || "N/A",
        size: item?.inventory?.product?.size || "N/A",
        color: item?.inventory?.product?.color || "N/A",
        finish: item?.inventory?.product?.finish || "N/A",
        thickness: item?.inventory?.product?.thickness || "N/A",
        mrp: item?.inventory?.mrp || "N/A",
        date: item?.product_date,
        stockInQty: 0,
        stockOutQty: 0,
        returnQty: 0,
        damageQty: item?.qty,
        uom: item?.inventory?.product?.uom || "N/A",
        ImageUrl: item?.inventory?.product?.ImageUrl || "N/A",
        batch: item?.inventory?.batch_id,
        type: "Damage",
    }));
    

    const mergedData = [...flatStockIn, ...flatStockOut, ...flatReturn, ...flatDamage].sort(
      (a, b) => new Date(b.date) - new Date(a.date)
    );
  
    return mergedData;
  };

  const mergedStockData = flattenStockData(stockInDataSuper, stockOutDataSuper,returnDataSuper,damageDataSuper);

  return (
    <>
      {status === "pending" && <Loader />}
      <Topbar activeTab={"transaction"} />
      <Transaction propData={mergedStockData} />
    </>
  );
};

export default SuperTransaction;
