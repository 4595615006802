import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllInventoryDataSuper } from "../../redux/Slices/SuperAdmin/productSlice";
import { getAdminProduct } from "../../redux/Slices/Admin/adminProductSlice";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  InputLabel,
  FormControl,
  MenuItem,
  TextField,
  Select,
  IconButton,
  Modal,
  Typography,
  Box,
} from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import Topbar from "../../components/Layout/Topbar";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import { AiOutlinePicture } from "react-icons/ai";
import MessageIcon from "@mui/icons-material/Message";
import { getBrand } from "../../redux/Slices/SuperAdmin/inventory/brandSlice";
import { getSize } from "../../redux/Slices/Measures/sizeSlice";
import { getCategory } from "../../redux/Slices/SuperAdmin/categorySlice";
import { getGodown } from "../../redux/Slices/SuperAdmin/inventory/godownSlice";
import { getFinish } from "../../redux/Slices/SuperAdmin/finishSlice";
import { deleteStockOut } from "../../redux/Slices/SuperAdmin/reportDeleteSlice";
import { FaTrash } from "react-icons/fa";
import Loader from "../../components/utils/Loader";
import {
  getStockOutAdmin,
  getStockOutSuper,
} from "../../redux/Slices/Report/reportSlice";

const StockoutReport = () => {
  const dispatch = useDispatch();

  const {
    stockOutDataAdmin = [],
    stockOutDataSuper = [],
    status,
    error,
  } = useSelector((state) => state.report);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const roleID = localStorage.getItem("roleId");
  const [selectedGodown, setSelectedGodown] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentRemarks, setCurrentRemarks] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedFinish, setSelectedFinish] = useState("");
  const { godownData = [] } = useSelector((state) => state.superAdminGodown);
  const { brandData = [] } = useSelector((state) => state.superAdminBrand);
  const { sizeData = [] } = useSelector((state) => state.superAdminSize);
  const { data = [] } = useSelector((state) => state.superAdminCategory);
  const { finishData = [] } = useSelector((state) => state.finish);
  const { deleteStatus } = useSelector((state) => state.reprtDelete);


  useEffect(() => {
    if (roleID === "1" || roleID === "4") dispatch(getStockOutSuper());
    else dispatch(getStockOutAdmin());
  }, [dispatch, deleteStatus]);

  useEffect(() => {
    dispatch(getBrand());
    dispatch(getSize());
    dispatch(getCategory());
    dispatch(getGodown());
    dispatch(getFinish());
  }, []);

  const handleGodownChange = (event) => {
    setSelectedGodown(event.target.value);
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
  };
  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleFinishChange = (event) => {
    setSelectedFinish(event.target.value);
  };

  const filteredInventories = (
    roleID === "1" || roleID === "4" ? stockOutDataSuper : stockOutDataAdmin
  )
    .filter(
      (product) =>
        (!selectedBrand || product.product?.brand?.id === selectedBrand) &&
        (!selectedCategory || product.product?.category_id === selectedCategory) &&
        (!selectedFinish || product.product?.finish === selectedFinish) &&
        (!selectedSize || product.product?.size === selectedSize) &&
        (!selectedGodown || product.stock_in_reports?.godowns_name?.name === selectedGodown) 
    )
    .filter((product) => {
      const searchLower = (searchQuery || "").toLowerCase();
      return (
        product.product?.name?.toLowerCase().includes(searchLower) ||
        product.product?.sku?.toLowerCase().includes(searchLower) ||
        product.product?.subcategory?.name?.toLowerCase().includes(searchLower) ||
        (product.batch?.toLowerCase() || "").includes(searchLower) 
      );
    });
  

  const downloadCSV = () => {
    const csvRows = [];

    // Add headers
    csvRows.push([
      roleID === "1" || roleID === "4" ? "GODOWN" : "",
      "SKU",
      "NAME",
      "BRAND",
      "CATEGORY",
      "SUB-CATEGORY",
      "BATCH",
      "STOCK OUT QTY",
      "UOM",
      "SIZE",
      "COLOR",
      "FINISH",
      "THICKNESS",
      "DATE",
    ]);

    filteredSortedArray?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.forEach((row) => {
        csvRows.push([
          roleID === "1" || roleID === "4" ? row.godownName : "",
          row.sku,
          row.name,
          row.brand,
          row.category,
          row.subCategory,
          row.batch,
          row.quantity,
          row.uom,
          row.size,
          row.color,
          row.finish,
          row.thickness,
          new Date(row.productDate).toLocaleDateString("en-GB", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          })
        ]);
      });

    const csvString = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", "stock_out_report.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleOpenModal = (remarks) => {
    setCurrentRemarks(remarks);
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
  };
  const isPending = status === "pending";

  const sortedArray = filteredInventories
    .flatMap((product) => ({
        id: product.product?.id,
        deleteId: product?.id,
        secondDeleteId: product?.stock_report_id,
        sku: product.product?.sku,
        name: product.product?.name,
        brand: product.product?.brand?.name || "N/A",
        category: product.product?.category?.name || "N/A",
        subCategory: product.product?.subcategory?.name || "N/A",
        batch: product.batch || "N/A",
        size: product.product?.size,
        color: product.product?.color,
        quantity: product.previous_qty || 0,
        uom: product.product?.uom,
        finish: product.product?.finish,
        thickness: product.product?.thickness,
        mrp: product.mrp || 0,
        remarks: product.remarks || "N/A",
        productDate: new Date(product.product_date || 0),
        godownName: product?.stock_in_reports?.godowns_name?.name || "N/A",
        imageUrl: product.product?.ImageUrl,
      }))
    .sort((a, b) => b.productDate - a.productDate);

  // const filteredSortedArray = sortedArray.filter((row) => {
  //   const productDate = new Date(row.productDate);
  //   const fromDateValid = !fromDate || productDate >= new Date(fromDate);
  //   const toDateValid =
  //     !toDate ||
  //     productDate <=
  //       new Date(new Date(toDate).setDate(new Date(toDate).getDate() ));

  //   return fromDateValid && toDateValid;
  // });

  const filteredSortedArray = sortedArray.filter((row) => {
    const productDate = new Date(row.productDate);
    const fromDateValid = !fromDate || productDate >= new Date(fromDate);
    const toDateValid = !toDate || productDate <= new Date(toDate);
  
    return fromDateValid && toDateValid;
  });
   

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    setRowsPerPage(
      value === "All" ? filteredSortedArray.length : parseInt(value, 10)
    );
    setPage(0);
  };

  return (
    <>

      {(roleID === "1" || roleID === "4") && (
        <Topbar activeTab={"stock out report"} />
      )}
      {isPending && <Loader />}
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <div
          style={{
            padding: "20px",
            background: "white",
            margin: "100px auto",
            maxWidth: "500px",
            borderRadius: "8px",
          }}
        >
          <Typography id="modal-title" variant="h6">
            Remarks
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
            {currentRemarks || "No remarks available"}
          </Typography>
        </div>
      </Modal>

      <div className="p-3 bg-gray-50 shadow-lg rounded-lg">
        <div className="flex justify-between items-center mb-4 bg-gradient-to-r gap-9 from-gray-600 to-gray-600 rounded-t-lg p-5 ">
          <span className="flex flex-col w-full">
            {/* top filters */}

            <Box
              display="flex"
              flexDirection="row"
              paddingTop={1}
              paddingBottom={2}
              gap={2}
            >
              {roleID === "1" && (
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    id="godown-select-label"
                    sx={{ color: "#FFFFFF" }}
                  >
                    Godown
                  </InputLabel>
                  <Select
                    labelId="godown-select-label"
                    value={selectedGodown}
                    onChange={handleGodownChange}
                    label="Godown"
                    sx={{
                      bgcolor: "#90E0",
                      color: "#FFFFFF",
                      "& .MuiSelect-icon": {
                        color: "#FFFFFF",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FFFFFF",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FFFFFF",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFFFF",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#FFFFFF",
                      },
                      "& .MuiSelect-select": {
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>All Godowns</em>
                    </MenuItem>
                    {godownData.map((godown) => (
                      <MenuItem key={godown.id} value={godown.name}>
                        {godown.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <FormControl fullWidth variant="outlined">
                <InputLabel id="brand-select-label" sx={{ color: "#FFFFFF" }}>
                  Brand
                </InputLabel>
                <Select
                  labelId="brand-select-label"
                  value={selectedBrand}
                  onChange={handleBrandChange}
                  label="Brand"
                  sx={{
                    bgcolor: "#90E00",
                    color: "#FFFFFF",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FFFFFF",
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Brands</em>
                  </MenuItem>
                  {brandData.map((brand) => (
                    <MenuItem key={brand.id} value={brand.id}>
                      {brand.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined">
                <InputLabel
                  id="category-select-label"
                  sx={{ color: "#FFFFFF" }}
                >
                  Category
                </InputLabel>
                <Select
                  labelId="category-select-label"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  label="Category"
                  sx={{
                    bgcolor: "#90E0",
                    color: "#FFFFFF",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FFFFFF",
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Categories</em>
                  </MenuItem>
                  {data.map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined">
                <InputLabel id="size-select-label" sx={{ color: "#FFFFFF" }}>
                  size
                </InputLabel>
                <Select
                  labelId="size-select-label"
                  value={selectedSize}
                  onChange={handleSizeChange}
                  label="Size"
                  sx={{
                    bgcolor: "#90E00",
                    color: "#FFFFFF",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FFFFFF",
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Sizes</em>
                  </MenuItem>
                  {sizeData.map((size) => (
                    <MenuItem key={size.id} value={size.name}>
                      {size.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined">
                <InputLabel id="finish-select-label" sx={{ color: "#FFFFFF" }}>
                  Finish
                </InputLabel>
                <Select
                  labelId="finish-select-label"
                  value={selectedFinish}
                  onChange={handleFinishChange}
                  label="Finish"
                  sx={{
                    bgcolor: "#90E0",
                    color: "#FFFFFF",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root ```javascript": {
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FFFFFF",
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Finishes</em>
                  </MenuItem>
                  {finishData.map((finish) => (
                    <MenuItem key={finish.id} value={finish.name}>
                      {finish.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <span className='flex items-center justify-between -mt-4"'>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border border-blue-600 rounded-lg w-[20rem] h-11 py-2 px-2 "
                placeholder="Search"
              />
              {/* {(roleID === '1' || roleID === '4') && (
                            <select
                                value={selectedGodown}
                                onChange={(e) => setSelectedGodown(e.target.value)}
                                className='border border-blue-600 rounded-lg w-[12rem] h-11 py-2 px-2 '
                            >
                                <option value="">All Godowns</option>
                                {godownOptions.map((godown, index) => (
                                    <option key={index} value={godown}>{godown}</option>
                                ))}
                            </select>
                        )} */}
              <div className="flex items-center">
                <input
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  className="border border-blue-600 rounded-lg w-[9rem] py-2 px-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                />

                <MultipleStopIcon className="text-white mx-2" />

                <input
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  className="border border-blue-600 rounded-lg w-[9rem] py-2 px-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>

              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={downloadCSV}
                className="bg-blue-600 text-white hover:bg-blue-700 transition-colors"
              >
                Download
              </Button>
            </span>
          </span>
        </div>
        <TableContainer
          component={Paper}
          className="rounded-lg mt-[-2rem] shadow-md overflow-hidden"
        >
          <Table
            aria-label="customized table"
            sx={{ width: "100%", tableLayout: "fixed" }}
          >
            <TableHead className="bg-gradient-to-r from-gray-600 to-gray-600">
              <TableRow className="">
                <TableCell
                  sx={{ color: "white", width: "100px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  SL No.
                </TableCell>
                {(roleID === "1" || roleID === "4") && (
                  <TableCell
                    sx={{ color: "white", width: "200px" }}
                    className="text-center text-xs font-bold py-3"
                  >
                    Godown
                  </TableCell>
                )}
                <TableCell
                  sx={{ color: "white", width: "140px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  SKU
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "240px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "150px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Brand
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "160px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Category
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "160px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Sub-Category
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "150px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Batch
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "120px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Size
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "150px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Color
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "80px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  QTY
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "80px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  UOM
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "160px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Finish
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "100px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Thickness
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "100px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  MRP
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "100px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Remarks
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "120px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "100px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Image
                </TableCell>
                {/* {roleID === "1" && (
                  <TableCell
                    sx={{ color: "white", width: "100px" }}
                    className="text-center text-xs font-bold py-3"
                  >
                    Action
                  </TableCell>
                )} */}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSortedArray
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={row.id + index + row.sku}
                    className="hover:bg-gray-100 transition-all text-sm"
                  >
                    <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                    {(roleID === "1" || roleID === "4") && (
                      <TableCell className="text-center py-1">
                        {row.godownName}
                      </TableCell>
                    )}
                    <TableCell className="text-center py-1">
                      {row.sku}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.name}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.brand}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.category}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.subCategory}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.batch}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.size}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.color}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.quantity}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.uom}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.finish}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.thickness}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.mrp}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => handleOpenModal(row.remarks)}>
                        <MessageIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {new Date(row.productDate).toLocaleDateString("en-GB", {
                        year: "numeric",
                        month: "2-digit",
                        day: "2-digit",
                      })}
                    </TableCell>
                    <TableCell>
                      {row.imageUrl && (
                        <div
                          className="cursor-pointer h-1 w-1 items-center"
                          onClick={() => setShowImage(row.imageUrl)}
                        >
                          <AiOutlinePicture size={24} />

                          {showImage === row.imageUrl && (
                            // Modal Background
                            <div
                              className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-10"
                              onClick={() => setShowImage(false)}
                            >
                              {/* Modal Content */}
                              <div
                                className="relative mt-16 bg-white rounded-lg shadow-lg p-4 overflow-hidden transform transition-all duration-300 ease-in-out"
                                style={{ width: "80%", maxWidth: "500px" }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                {/* Image */}
                                <div className="flex justify-center">
                                  <img
                                    src={row.imageUrl}
                                    alt="Product"
                                    className="w-full h-full object-cover rounded-lg"
                                    style={{ maxHeight: "70vh" }}
                                  />
                                </div>

                                {/* Action Buttons */}
                                <div className="mt-4 flex justify-center space-x-4">
                                  <button
                                    className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 rounded-lg"
                                    onClick={() => setShowImage(false)}
                                  >
                                    Close
                                  </button>
                                  <button
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg"
                                    onClick={() =>
                                      window.open(row.imageUrl, "_blank")
                                    }
                                  >
                                    Open in New Tab
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </TableCell>
                    {/* <TableCell>
                      <span className="flex">
                        {roleID === "1" && (
                          <IconButton
                            onClick={() => {
                              dispatch(deleteStockOut({report_id:row.deleteId,stock_report_id:row.secondDeleteId}));
                            }}
                            aria-label="delete"
                          >
                            <FaTrash className="text-red-600" />
                          </IconButton>
                        )}
                      </span>
                    </TableCell> */}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={filteredSortedArray.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100, "All"]}
          className="mt-4"
        />
      </div>
    </>
  );
};

export default StockoutReport;
