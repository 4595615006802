import React, { useState } from 'react';
import { Box, Button, TextField, Typography, Modal } from '@mui/material';
import { useDispatch } from 'react-redux';
import { addUploads } from '../../../redux/Slices/SuperAdmin/web/uploadSlice';

const CatalogUploadModal = ({ open, onClose }) => {
    const dispatch = useDispatch();
    const [catalogFile, setCatalogFile] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const handleCatalogSubmit = (e) => {
        e.preventDefault();
        if (catalogFile) {
            const payload = {
                title,
                description,
                catalog: catalogFile,
                image: imageFile
            };
            dispatch(addUploads(payload));
            // onClose(); 
        }
    };

    return (
        <Modal open={open} onClose={onClose}>
            <Box
                sx={{
                    bgcolor: 'background.paper',
                    boxShadow: 3,
                    borderRadius: 2,
                    p: 4,
                    width: '400px', // Set a specific width
                    position: 'absolute', // Positioning for centering
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)', // Center vertically and horizontally
                }}
            >
                <Typography variant="h6" color="textPrimary" gutterBottom>
                    Upload Catalog and Brochures
                </Typography>
                <form onSubmit={handleCatalogSubmit}>
                    {/* Title Input */}
                    <TextField
                        label="Title"
                        variant="outlined"
                        fullWidth
                        onChange={(e) => setTitle(e.target.value)}
                        margin="normal"
                    />

                    {/* Catalog File Input */}
                    <div style={{ marginTop: '16px' }}>
                        <label htmlFor="catalog-file" style={{ display: 'block', marginBottom: '8px' }}>
                            Catalog File
                        </label>
                        <input
                            id="catalog-file"
                            type="file"
                            onChange={(e) => setCatalogFile(e.target.files[0])}
                            accept=".pdf,.doc,.docx,.ppt,.pptx"
                            style={{ width: '100%' }} // Optional styling for the file input
                        />
                    </div>

                    {/* Image File Input */}
                    <div style={{ marginTop: '16px' }}>
                        <label htmlFor="image-file" style={{ display: 'block', marginBottom: '8px' }}>
                            Image
                        </label>
                        <input
                            id="image-file"
                            type="file"
                            onChange={(e) => setImageFile(e.target.files[0])}
                            accept="image/*" // You can specify accepted image formats if desired
                            style={{ width: '100%' }} // Optional styling for the file input
                        />
                    </div>

                    {/* Submit Button */}
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ mt: 2 }}
                    >
                        Upload Catalog
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default CatalogUploadModal;