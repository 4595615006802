import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from '../../apiConfig';
import { enqueueSnackbar } from "notistack";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const getSize = createAsyncThunk(
    "super_admin/getSize",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/size`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const addSize = createAsyncThunk(
    "addSize",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/size/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }
            enqueueSnackbar("Added succesfully!", { variant: "success" ,  className: 'outlined-snackbar'});
            const data = await response.json();
            return data;
        } catch (error) {
            enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const updateSize = createAsyncThunk(
    "updateSize",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/size/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: 'application/json',
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }
            enqueueSnackbar("Updated succesfully!", { variant: "success" ,  className: 'outlined-snackbar'});

            const data = await response.json();
            return data;
        } catch (error) {
            enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const deleteSize = createAsyncThunk(
    "deleteSize",
    async (id, { rejectWithValue }) => {
        console.log("Delete thunk called with id:", id);

        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/size/delete/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }
            enqueueSnackbar("Deleted succesfully!", { variant: "success" ,  className: 'outlined-snackbar'});

            const data = await response.json();
            return data;
        } catch (error) {
            enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);


const superAdminSizesSlice = createSlice({
    name: 'size',
    initialState: {
        sizeData: [],
        getStatus: 'idle',
        addStat: 'idle',
        updateStat: 'idle',
        deleteStat: 'idle',
        error: null,
        size: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getSize.pending, (state) => {
                state.getStatus = 'pending';
            })
            .addCase(getSize.fulfilled, (state, action) => {
                state.getStatus = 'succeeded';
                state.sizeData = action.payload.data;
            })
            .addCase(getSize.rejected, (state, action) => {
                state.getStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addSize.pending, (state) => {
                state.addStat = 'pending';
            })
            .addCase(addSize.fulfilled, (state, action) => {
                state.addStat = 'succeeded';
            })
            .addCase(addSize.rejected, (state, action) => {
                state.addStat = 'failed';
                state.error = action.payload;
            })
            .addCase(updateSize.pending, (state) => {
                state.updateStat = 'pending';
            })
            .addCase(updateSize.fulfilled, (state, action) => {
                state.updateStat = 'succeeded';
            })
            .addCase(updateSize.rejected, (state, action) => {
                state.updateStat = 'failed';
                state.error = action.payload;
            })
            .addCase(deleteSize.pending, (state) => {
                state.deleteStat = 'pending';
            })
            .addCase(deleteSize.fulfilled, (state, action) => {
                state.deleteStat = 'succeeded';
            })
            .addCase(deleteSize.rejected, (state, action) => {
                state.deleteStat = 'failed';
                state.error = action.payload;
            });
    }
});

export default superAdminSizesSlice.reducer;
