import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getRequest } from '../../redux/Slices/SuperAdmin/web/requestSlice';
import Topbar from '../../components/Layout/Topbar';
import {
    Box,
    Typography,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Tooltip,
    IconButton,
    TextField,
    Snackbar,
    Alert
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Loader from '../../components/utils/Loader';

const Request = () => {
    const { requestData = [], getStat } = useSelector(state => state.request);
    const dispatch = useDispatch();
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    useEffect(() => {
        dispatch(getRequest());
    }, [dispatch]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const filteredRequests = requestData.filter(request =>
        request.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.phone.includes(searchTerm) ||
        request.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.sku.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <>
            {getStat === 'pending' && (
                <Loader />
            )}
            <Topbar activeTab="order request" />
            <Container component="main">
                <Box
                    sx={{
                        bgcolor: '#1f1f2e',
                        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.4)',
                        borderRadius: 3,
                        p: 5,
                        mt: 4,
                        color: 'white',
                        mx: 'auto',
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 4 }}>
                        <Typography variant="h4" color="primary" sx={{ fontWeight: 'bold' }}>
                            Order Requests
                        </Typography>
                        <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            sx={{
                                width: '300px',
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: '#393a4d',
                                    borderRadius: '5px',
                                    '& fieldset': {
                                        borderColor: '#3498db',
                                    },
                                    '&.Mui-focused fieldset': {
                                        borderColor: '#3498db',
                                    },
                                    '& input': {
                                        color: 'white',
                                        padding: '10px 12px',
                                    },
                                },
                            }}
                            value={searchTerm}
                            onChange={handleSearchChange}
                            slotProps={{
                                input: {
                                    style: {
                                        backgroundColor: '#393a4d',
                                        color: 'white',
                                    },
                                },
                            }}
                        />

                    </Box>

                    <TableContainer component={Paper} sx={{ bgcolor: '#28293e', borderRadius: 2, mt: 3, boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ color: '#bdbdbd', fontWeight: 'bold', fontSize: '16px' }}>SL No.</TableCell>
                                    <TableCell sx={{ color: '#bdbdbd', fontWeight: 'bold', fontSize: '16px' }}>Name</TableCell>
                                    <TableCell sx={{ color: '#bdbdbd', fontWeight: 'bold', fontSize: '16px' }}>Phone</TableCell>
                                    <TableCell sx={{ color: '#bdbdbd', fontWeight: 'bold', fontSize: '16px' }}>Email</TableCell>
                                    <TableCell sx={{ color: '#bdbdbd', fontWeight: 'bold', fontSize: '16px' }}>SKU</TableCell>
                                    <TableCell sx={{ color: '#bdbdbd', fontWeight: 'bold', fontSize: '16px' }}>QTY</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredRequests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((request, index) => (
                                    <TableRow key={request.id} sx={{ '&:hover': { bgcolor: '#393a4d' } }}>
                                        <TableCell sx={{ color: '#e0e0e0' }}>{index + 1}</TableCell>
                                        <TableCell sx={{ color: '#e0e0e0' }}>{request.name}</TableCell>
                                        <TableCell sx={{ color: '#e0e0e0' }}>{request.phone}</TableCell>
                                        <TableCell sx={{ color: '#e0e0e0' }}>{request.email}</TableCell>
                                        <TableCell sx={{ color: '#e0e0e0' }}>{request.sku}</TableCell>
                                        <TableCell sx={{ color: '#e0e0e0' }}>{request.qty}</TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            component="div"
                            count={filteredRequests.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            sx={{
                                bgcolor: '#28293e',
                                color: '#b0bec5',
                                '& .MuiTablePagination-actions button': { color: '#b0bec5' },
                            }}
                        />
                    </TableContainer>
                </Box>
            </Container>
            {/* <Snackbar open={getStat === 'pending'} autoHideDuration={6000}>
                <Alert severity="info" sx={{ bgcolor: '#4caf50', color: 'white' }}>Loading ...</Alert>
            </Snackbar> */}
        </>
    );
};

export default Request;
