import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { enqueueSnackbar } from "notistack";


const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};


export const getAdminProduct = createAsyncThunk(
    "admin/getProduct",
    async ({ page, rowsPerPage, searchQuery,selectedBrand,selectedCategory,selectedSize ,selectedFinish}, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product/products?page=${page + 1}&limit=${rowsPerPage}&search=${searchQuery || ""}&brand=${selectedBrand || ""}&category=${selectedCategory || ""}&size=${selectedSize || ""}&finish=${selectedFinish || ""}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);
export const getAllInventoryDataAdmin = createAsyncThunk(
    "admin/getAllData",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const getAdminInventory = createAsyncThunk(
    "admin/getInventory",
    async ({ page2, rowsPerPage2, searchQuery,selectedBrand,selectedCategory,selectedSize ,selectedFinish}, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product/inventory?page=${page2 + 1}&limit=${rowsPerPage2}&search=${searchQuery || ""}&brand=${selectedBrand || ""}&category=${selectedCategory || ""}&size=${selectedSize || ""}&finish=${selectedFinish || ""}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const addAdminProduct = createAsyncThunk(
    "admin/addProduct",
    async (productData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const product = productData.products[0];

            const formData = new FormData();


            if (product.image && product.image instanceof File) {
                formData.append('image', product.image);
            }

            if (product.image2 && product.image2 instanceof File) {
                formData.append('image2', product.image2);
            }
            if (product.image3 && product.image3 instanceof File) {
                formData.append('image3', product.image3);
            }
            if (product.image4 && product.image4 instanceof File) {
                formData.append('image4', product.image4);
            }

            const productWithoutImage = {
                name: product.name,
                sku: product.sku,
                size: product.size,
                qty: product.qty,
                godowns_id: product.godowns_id,
                uom: product.uom,
                product_in_date: product.product_in_date,
                color: product.color,
                brand: product.brand,
                category_id: product.category_id,
                sub_category: product.sub_category,
                thickness: product.thickness,
                finish: product.finish
            };

            Object.entries(productWithoutImage).forEach(([key, value]) => {
                formData.append(key, value);
            });

            const response = await fetch(`${apiUrl}admin/product/add`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Add failed!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }
            enqueueSnackbar("Product added succesfully!", { variant: "success" ,  className: 'outlined-snackbar'});

            const data = await response.json();
            return data;
        } catch (error) {

            return rejectWithValue({ message: 'An error occurred while adding the product.' });
        }
    }
);



export const productAdminUpdate = createAsyncThunk(
    "admin/productUpdate",
    async (productData, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const formData = new FormData();

            // Append product data to FormData
            formData.append('id', productData.id);
            formData.append('name', productData.name);
            formData.append('brand', productData.brand);
            formData.append('category_id', productData.category_id);
            formData.append('sub_category', productData.sub_category);
            formData.append('size', productData.size);
            formData.append('uom', productData.uom);
            formData.append('product_in_date', productData.product_in_date);
            formData.append('color', productData.color);
            formData.append('description', productData.desc);
            formData.append('thickness', productData.thickness);
            formData.append('finish', productData.finish);
            formData.append('dis_continue', productData.dis_continue);

            if (productData.image && productData.image instanceof File) {
                formData.append('image', productData.image);
            }

            if (productData.image2 && productData.image2 instanceof File) {
                formData.append('image2', productData.image2);
            }
            if (productData.image3 && productData.image3 instanceof File) {
                formData.append('image3', productData.image3);
            }
            if (productData.image4 && productData.image4 instanceof File) {
                formData.append('image4', productData.image4);
            }

            const response = await fetch(`${apiUrl}admin/product/update`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
                body: formData,
            });

            if (!response.ok) {

                const errorData = await response.json();

                enqueueSnackbar(errorData.error || "Update failed!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {

            return rejectWithValue({ message: 'An error occurred while updating the product.' });
        }
    }
);

export const productAdminDelete = createAsyncThunk(
    "productdelete",
    async (id, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/delete/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {

                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Delete failed!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }
            enqueueSnackbar("Product deleted succesfully!", { variant: "success" ,  className: 'outlined-snackbar'});

            const data = await response.json();
            return data;
        } catch (error) {

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const initialState = {
    adminAddStatus: 'idle',
    AdminProducts: [],
    AdminInventory: [],
    allInvDataAdmin: [],
    totalProductsAdmin:0,
    totalInventoryAdmin:0,
    adminGetStatus: 'idle',
    adminInvStatus:'idle',
    adminUpdateStatus: 'idle',
    adminDeleteStatus: 'idle',
    otherStatAdmin: 'idle',
    error: null,
    product: null,
};

const adminProductSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getAdminProduct.pending, (state) => {
                state.adminGetStatus = 'pending';
            })
            .addCase(getAdminProduct.fulfilled, (state, action) => {
                state.adminGetStatus = 'succeeded';
                state.AdminProducts = action.payload.data;
                state.totalProductsAdmin = action.payload.count;
                console.log(action.payload.count)
            })
            .addCase(getAdminProduct.rejected, (state, action) => {
                state.adminGetStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(getAllInventoryDataAdmin.pending, (state) => {
                state.otherStatAdmin = 'pending';
            })
            .addCase(getAllInventoryDataAdmin.fulfilled, (state, action) => {
                state.otherStatAdmin = 'succeeded';
                state.allInvDataAdmin = action.payload.data;
            })
            .addCase(getAllInventoryDataAdmin.rejected, (state, action) => {
                state.otherStatAdmin = 'failed';
                state.error = action.payload;
            })
            .addCase(getAdminInventory.pending, (state) => {
                state.adminInvStatus = 'pending';
            })
            .addCase(getAdminInventory.fulfilled, (state, action) => {
                state.adminInvStatus = 'succeeded';
                state.AdminInventory = action.payload.data;
                state.totalInventoryAdmin = action.payload.count;
            })
            .addCase(getAdminInventory.rejected, (state, action) => {
                state.adminInvStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addAdminProduct.pending, (state) => {
                state.adminAddStatus = 'pending';
            })
            .addCase(addAdminProduct.fulfilled, (state, action) => {
                state.adminAddStatus = 'succeeded';
                state.product = action.payload;
            })
            .addCase(addAdminProduct.rejected, (state, action) => {
                state.adminAddStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(productAdminUpdate.pending, (state) => {
                state.adminUpdateStatus = 'pending';
            })
            .addCase(productAdminUpdate.fulfilled, (state, action) => {
                state.adminUpdateStatus = 'succeeded';
            })
            .addCase(productAdminUpdate.rejected, (state, action) => {
                state.adminUpdateStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(productAdminDelete.pending, (state) => {
                state.deleteStatus = 'pending';
            })
            .addCase(productAdminDelete.fulfilled, (state, action) => {
                state.deleteStatus = 'succeeded';
            })
            .addCase(productAdminDelete.rejected, (state, action) => {
                state.deleteStatus = 'failed';
                state.error = action.payload;
            });
    }
});

export default adminProductSlice.reducer;
