// src/components/Layout/Layout.js
import React, { useState } from 'react';
import AdminSidebar from './AdminSidebar';
import { Outlet } from 'react-router-dom';

const AdminLayout = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    return (
        <div className="flex">
            <div className={` ${isSidebarOpen ? 'w-64' : 'w-20'} transition-all duration-300 overflow-auto`}>
                <AdminSidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            </div>
            {/* Main Content */}
            <div className={`flex-1 mt-20 transition-all duration-300 overflow-auto`}>
                <Outlet />
            </div>
        </div>
    );
};

export default AdminLayout;
