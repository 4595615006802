import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllInventoryDataSuper } from "../../redux/Slices/SuperAdmin/productSlice";
import { getAdminProduct } from "../../redux/Slices/Admin/adminProductSlice";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  TextField,
  Box,
} from "@mui/material";
import {
  Download as DownloadIcon,
  RollerShadesOutlined,
} from "@mui/icons-material";
import Topbar from "../../components/Layout/Topbar";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import { AiOutlinePicture } from "react-icons/ai";
import { getBrand } from "../../redux/Slices/SuperAdmin/inventory/brandSlice";
import { getCategory } from "../../redux/Slices/SuperAdmin/categorySlice";
import { getGodown } from "../../redux/Slices/SuperAdmin/inventory/godownSlice";
import { getFinish } from "../../redux/Slices/SuperAdmin/finishSlice";
import { getSize } from "../../redux/Slices/Measures/sizeSlice";
import Loader from "../../components/utils/Loader";
import {
  getStockInAdmin,
  getStockInSuper,
  getStockOutSuper,
} from "../../redux/Slices/Report/reportSlice";

const StockinReport = () => {
  const dispatch = useDispatch();
  const {
    stockInDataAdmin = [],
    stockInDataSuper = [],
    status,
    error,
  } = useSelector((state) => state.report);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedGodown, setSelectedGodown] = useState("");
  const roleID = localStorage.getItem("roleId");
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [showImage, setShowImage] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedFinish, setSelectedFinish] = useState("");
  const { godownData = [] } = useSelector((state) => state.superAdminGodown);
  const { brandData = [] } = useSelector((state) => state.superAdminBrand);
  const { sizeData = [] } = useSelector((state) => state.superAdminSize);
  const { data = [] } = useSelector((state) => state.superAdminCategory);
  const { finishData = [] } = useSelector((state) => state.finish);

  useEffect(() => {
    if (roleID === "1" || roleID === "4") dispatch(getStockInSuper());
    else dispatch(getStockInAdmin());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getBrand());
    dispatch(getSize());
    dispatch(getCategory());
    dispatch(getGodown());
    dispatch(getFinish());
  }, []);

  const handleGodownChange = (event) => {
    setSelectedGodown(event.target.value);
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
  };
  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleFinishChange = (event) => {
    setSelectedFinish(event.target.value);
  };

  const filteredInventories = (
    roleID === "1" || roleID === "4" ? stockInDataSuper : stockInDataAdmin
  )
    .filter(
      (product) =>
        (!selectedSize ||
          (product.product?.size && product?.product?.size === selectedSize)) &&
        (!selectedBrand ||
          (product.product?.brand?.name &&
            product.product?.brand?.name === selectedBrand)) &&
        (!selectedCategory ||
          (product.product?.category?.name &&
            product.product?.category?.name === selectedCategory)) &&
        (!selectedFinish ||
          (product.product?.finish && product?.product.finish === selectedFinish))
    )
    .filter((product) => {
      const itemDate = new Date(product.product_date); 
      const from = fromDate ? new Date(fromDate) : null;
      const to = toDate ? new Date(toDate) : null;
  
      if (to) {
        to.setDate(to.getDate());
      }
  
      return (
        (!selectedGodown ||
          (product?.godowns_name && product?.godowns_name?.name === selectedGodown)) &&
        (!from || itemDate >= from) &&
        (!to || itemDate <= to)
      );
    })
    .filter((product) => {
      const searchLower = searchQuery.toLowerCase();
      return (
        product.product?.name?.toLowerCase().includes(searchLower) ||
        product.product?.sku?.toLowerCase().includes(searchLower) ||
        product.product?.subcategory?.name?.toLowerCase().includes(searchLower) ||
        product.batch_id?.toLowerCase().includes(searchLower) ||
        (product.godowns_name &&
          product.godowns_name?.name.toLowerCase().includes(searchLower))
      );
    });
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    setRowsPerPage(
      value === "All"
        ? filteredInventories.flatMap((product) => product).length
        : parseInt(value, 10)
    );
    setPage(0);
  };

  const downloadCSV = () => {
    const csvRows = [];
    csvRows.push([
      roleID === "1" || roleID === "4" ? "GODOWN" : "",
      "SKU",
      "NAME",
      "BRAND",
      "CATEGORY",
      "SUB-CATEGORY",
      "BATCH",
      "STOCK IN QTY",
      "UOM",
      "MRP",
      "SIZE",
      "COLOR",
      "FINISH",
      "THICKNESS",
      "DATE",
    ]);

    const data = finalSortedArray.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    data?.forEach((row) => {
      csvRows.push([
        roleID === "1" || roleID === "4" ? row?.GODOWN : "",
        row.SKU,
        row.NAME,
        row.BRAND,
        row.CATEGORY,
        row["SUB-CATEGORY"],
        row.BATCH || "N/A",
        row["STOCK IN QTY"],
        row.UOM,
        row.MRP||'N/A',
        row.SIZE,
        row.COLOR,
        row.FINISH,
        row.THICKNESS,
        row.DATE
      ]);
    });

    const csvString = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", "stock_in_report.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const isPending = status === "pending";

  const sortedArray = filteredInventories?.map((product) =>
    ({
      GODOWN: roleID === "1" || roleID === "4" ? product?.godowns_name?.name : "",
      SKU: product.product?.sku,
      id: product.product?.id,
      NAME: product.product?.name,
      BRAND: product.product?.brand?.name,
      CATEGORY: product.product?.category?.name,
      "SUB-CATEGORY": product.product?.subcategory?.name,
      BATCH: product.batch_id || "N/A",
      "STOCK IN QTY": product?.total_qty,
      UOM: product.product?.uom,
      MRP: product.mrp,
      SIZE: product.product?.size,
      COLOR: product.product?.color,
      FINISH: product.product?.finish,
      THICKNESS: product.product?.thickness,
      DATE: new Date(product?.product_date),
      ImageUrl: product.product?.ImageUrl,
    }));

  // Sort the array by DATE in descending order
  sortedArray.sort((a, b) => b.DATE - a.DATE);

  // Optionally, if you want to format the date back to a string after sorting
  const finalSortedArray = sortedArray.map((item) => ({
    GODOWN: item.GODOWN,
    SKU: item.SKU,
    id: item.id,
    NAME: item.NAME,
    BRAND: item.BRAND,
    CATEGORY: item.CATEGORY,
    "SUB-CATEGORY": item["SUB-CATEGORY"],
    BATCH: item?.BATCH,
    "STOCK IN QTY": item["STOCK IN QTY"] || 0,
    UOM: item.UOM,
    MRP: item.MRP,
    SIZE: item.SIZE,
    COLOR: item.COLOR,
    FINISH: item.FINISH,
    THICKNESS: item.THICKNESS,
    ImageUrl: item.ImageUrl,
    DATE: item?.DATE.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
    }),
  }));

  return (
    <>
      {(roleID === "1" || roleID === "4") && (
        <Topbar activeTab={"stock in report"} />
      )}
      {isPending && <Loader />}
      <div className="p-3 bg-gray-50 shadow-lg rounded-lg">
        <div className="flex justify-between items-center mb-4 bg-gradient-to-r  from-gray-600 to-gray-600 rounded-t-lg p-5 ">
          <span className="flex flex-col w-full">
            {/* top filter */}
            <Box
              display="flex"
              flexDirection="row"
              paddingTop={1}
              paddingBottom={2}
              gap={2}
            >
              {roleID === "1" && (
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    id="godown-select-label"
                    sx={{ color: "#FFFFFF" }}
                  >
                    Godown
                  </InputLabel>
                  <Select
                    labelId="godown-select-label"
                    value={selectedGodown}
                    onChange={handleGodownChange}
                    label="Godown"
                    sx={{
                      bgcolor: "#90E0",
                      color: "#FFFFFF",
                      "& .MuiSelect-icon": {
                        color: "#FFFFFF",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FFFFFF",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FFFFFF",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFFFF",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#FFFFFF",
                      },
                      "& .MuiSelect-select": {
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>All Godowns</em>
                    </MenuItem>
                    {godownData.map((godown) => (
                      <MenuItem key={godown.id} value={godown.name}>
                        {godown.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <FormControl fullWidth variant="outlined">
                <InputLabel id="brand-select-label" sx={{ color: "#FFFFFF" }}>
                  Brand
                </InputLabel>
                <Select
                  labelId="brand-select-label"
                  value={selectedBrand}
                  onChange={handleBrandChange}
                  label="Brand"
                  sx={{
                    bgcolor: "#90E00",
                    color: "#FFFFFF",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FFFFFF",
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Brands</em>
                  </MenuItem>
                  {brandData.map((brand) => (
                    <MenuItem key={brand.id} value={brand.name}>
                      {brand.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined">
                <InputLabel
                  id="category-select-label"
                  sx={{ color: "#FFFFFF" }}
                >
                  Category
                </InputLabel>
                <Select
                  labelId="category-select-label"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  label="Category"
                  sx={{
                    bgcolor: "#90E0",
                    color: "#FFFFFF",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FFFFFF",
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Categories</em>
                  </MenuItem>
                  {data.map((category) => (
                    <MenuItem key={category.id} value={category.name}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined">
                <InputLabel id="size-select-label" sx={{ color: "#FFFFFF" }}>
                  size
                </InputLabel>
                <Select
                  labelId="size-select-label"
                  value={selectedSize}
                  onChange={handleSizeChange}
                  label="Size"
                  sx={{
                    bgcolor: "#90E00",
                    color: "#FFFFFF",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FFFFFF",
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Sizes</em>
                  </MenuItem>
                  {sizeData.map((size) => (
                    <MenuItem key={size.id} value={size.name}>
                      {size.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined">
                <InputLabel id="finish-select-label" sx={{ color: "#FFFFFF" }}>
                  Finish
                </InputLabel>
                <Select
                  labelId="finish-select-label"
                  value={selectedFinish}
                  onChange={handleFinishChange}
                  label="Finish"
                  sx={{
                    bgcolor: "#90E0",
                    color: "#FFFFFF",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root ```javascript": {
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FFFFFF",
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Finishes</em>
                  </MenuItem>
                  {finishData.map((finish) => (
                    <MenuItem key={finish.id} value={finish.name}>
                      {finish.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <span className='flex items-center justify-between -mt-4"'>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border border-blue-600 rounded-lg w-[20rem] h-11 py-2 px-2 "
                placeholder="Search..."
              />
              <div className="flex items-center">
                <input
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  className="border border-blue-600 rounded-lg w-[9rem] py-2 px-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                />

                <MultipleStopIcon className="text-white mx-2" />

                <input
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  className="border border-blue-600 rounded-lg w-[9rem] py-2 px-2 focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>

              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={downloadCSV}
                className="bg-blue-600 text-white hover:bg-blue-700 transition-colors"
              >
                Download
              </Button>
            </span>
          </span>
        </div>

        <TableContainer
          component={Paper}
          className="rounded-lg mt-[-2rem] shadow-md "
        >
          <Table
            aria-label="customized table"
            sx={{ width: "100%", tableLayout: "fixed" }}
          >
            <TableHead className="bg-gradient-to-r from-gray-600 to-gray-600">
              <TableRow className="">
                <TableCell
                  sx={{ color: "white", width: "80px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  SL No.
                </TableCell>
                {(roleID === "1" || roleID === "4") && (
                  <TableCell
                    sx={{ color: "white", width: "200px" }}
                    className="text-center text-xs font-bold py-3"
                  >
                    Godown
                  </TableCell>
                )}
                <TableCell
                  sx={{ color: "white", width: "140px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  SKU
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "240px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "150px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Brand
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "160px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Category
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "160px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Sub-Category
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "150px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Batch
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "120px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Size
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "150px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Color
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "80px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  QTY
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "80px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  UOM
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "160px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Finish
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "100px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Thickness
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "100px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  MRP
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "120px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Date
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "100px" }}
                  className="text-center text-xs font-bold py-3"
                >
                  Image
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {finalSortedArray
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={index}
                    className="hover:bg-gray-100 transition-all text-sm"
                  >
                    <TableCell>{index + 1 + page * rowsPerPage}</TableCell>
                    {(roleID === "1" || roleID === "4") && (
                      <TableCell className="text-center py-1">
                        {row.GODOWN}
                      </TableCell>
                    )}
                    <TableCell className="text-center py-1">
                      {row.SKU}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.NAME}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.BRAND}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.CATEGORY}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row["SUB-CATEGORY"]}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.BATCH}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.SIZE}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.COLOR}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row["STOCK IN QTY"]}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.UOM}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.FINISH}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.THICKNESS}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      ₹{row.MRP}
                    </TableCell>
                    <TableCell className="text-center py-1">
                      {row.DATE}
                    </TableCell>
                    <TableCell>
                      {row.ImageUrl !==
                        "https://inventory.risapp.co.in/public/storage" && (
                        <div
                          className="cursor-pointer h-1 w-1 items-center"
                          onClick={() => setShowImage(row.ImageUrl)}
                        >
                          <AiOutlinePicture size={24} />
                          {showImage === row.ImageUrl && (
                            // Modal Background
                            <div
                              className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-10"
                              onClick={() => setShowImage(false)}
                            >
                              {/* Modal Content */}
                              <div
                                className="relative mt-16 bg-white rounded-lg shadow-lg p-4 overflow-hidden transform transition-all duration-300 ease-in-out"
                                style={{ width: "80%", maxWidth: "500px" }}
                                onClick={(e) => e.stopPropagation()}
                              >
                                {/* Image */}
                                <div className="flex justify-center">
                                  <img
                                    src={row.ImageUrl}
                                    alt="Image"
                                    className="w-full h-full object-cover rounded-lg"
                                    style={{ maxHeight: "70vh" }}
                                  />
                                </div>
                                {/* Action Buttons */}
                                <div className="mt-4 flex justify-center space-x-4">
                                  <button
                                    className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 rounded-lg"
                                    onClick={() => setShowImage(false)}
                                  >
                                    Close
                                  </button>
                                  <button
                                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg"
                                    onClick={() =>
                                      window.open(row.ImageUrl, "_blank")
                                    }
                                  >
                                    Open in New Tab
                                  </button>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={finalSortedArray.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100, "All"]}
          className="mt-4"
        />
      </div>
    </>
  );
};

export default StockinReport;
