import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from '../../apiConfig';
import { enqueueSnackbar } from "notistack";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const getCategory = createAsyncThunk(
    "super_admin/getCategory",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/category`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const addCategory = createAsyncThunk(
    "addCategory",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/category/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }
            enqueueSnackbar("Category added succesfully!", { variant: "success" ,  className: 'outlined-snackbar'});

            const data = await response.json();
            return data;
        } catch (error) {
enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const updateCategory = createAsyncThunk(
    "updateCategory",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/category/update`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: JSON.stringify(datas),
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }
            enqueueSnackbar("Category updated succesfully!", { variant: "success" ,  className: 'outlined-snackbar'});

            const data = await response.json();
            return data;
        } catch (error) {
enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const categoryDelete = createAsyncThunk(
    "categoryDelete",
    async (id, { rejectWithValue }) => {
        console.log("Delete thunk called with id:", id);

        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/category/delete/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }
            enqueueSnackbar("Category deleted succesfully!", { variant: "success" ,  className: 'outlined-snackbar'});

            const data = await response.json();
            return data;
        } catch (error) {
enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const superAdminCategorySlice = createSlice({
    name: 'category',
    initialState: {
        data: [],
        getStatus: 'idle',
        addStatus: 'idle',
        updateStatus: 'idle',
        deleteStatus: 'idle',
        error: null,
        category: null
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getCategory.pending, (state) => {
                state.getStatus = 'pending';
            })
            .addCase(getCategory.fulfilled, (state, action) => {
                state.getStatus = 'succeeded';
                state.data = action.payload.data;
            })
            .addCase(getCategory.rejected, (state, action) => {
                state.getStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(addCategory.pending, (state) => {
                state.addStatus = 'pending';
            })
            .addCase(addCategory.fulfilled, (state, action) => {
                state.addStatus = 'succeeded';
                state.category = action.payload;
            })
            .addCase(addCategory.rejected, (state, action) => {
                state.addStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(updateCategory.pending, (state) => {
                state.updateStatus = 'pending';
            })
            .addCase(updateCategory.fulfilled, (state, action) => {
                state.updateStatus = 'succeeded';
                state.category = action.payload;
            })
            .addCase(updateCategory.rejected, (state, action) => {
                state.updateStatus = 'failed';
                state.error = action.payload;
            })
            .addCase(categoryDelete.pending, (state) => {
                state.deleteStatus = 'pending';
            })
            .addCase(categoryDelete.fulfilled, (state, action) => {
                state.deleteStatus = 'succeeded';
            })
            .addCase(categoryDelete.rejected, (state, action) => {
                state.deleteStatus = 'failed';
                state.error = action.payload;
            });
    }
});

export default superAdminCategorySlice.reducer;
