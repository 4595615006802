import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { enqueueSnackbar } from "notistack";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};


export const getDamaged = createAsyncThunk(
    "out/getDamaged",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product/demage/history`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);
export const getDamagedSuper = createAsyncThunk(
    "damage/getDamagedSuper",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/demage/history`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);
export const getReturned = createAsyncThunk(
    "in/getReturned",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product/return/history`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);
export const getReturnedSuper = createAsyncThunk(
    "return/getReturnedSuper",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/return/history`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);
export const getStockOutAdmin = createAsyncThunk(
    "out/getStockOutAdmin",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product/stock-out/reports`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);
export const getStockOutSuper = createAsyncThunk(
    "out/getStockOutSuper",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/stock-out/reports`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);
export const getStockInAdmin = createAsyncThunk(
    "in/getStockInAdmin",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}admin/product/stock-in/reports`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);
export const getStockInSuper = createAsyncThunk(
    "in/getStockInSuper",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/product/stock-in/reports`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const initialState = {
    returnData: [],
    damageData: [],
    returnDataSuper: [],
    damageDataSuper: [],
    stockInDataSuper:[],
    stockInDataAdmin:[],
    stockOutDataSuper:[],
    stockOutDataAdmin:[],
    stockOut:[],
    status: 'idle',
    error: null,
};

const godownReportSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getReturned.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(getReturned.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.returnData = action.payload.data;
            })
            .addCase(getReturned.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(getReturnedSuper.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(getReturnedSuper.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.returnDataSuper = action.payload.data;
            })
            .addCase(getReturnedSuper.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(getDamaged.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(getDamaged.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.damageData = action.payload.data;
            })
            .addCase(getDamaged.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(getDamagedSuper.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(getDamagedSuper.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.damageDataSuper = action.payload.data;
            })
            .addCase(getDamagedSuper.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(getStockInSuper.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(getStockInSuper.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.stockInDataSuper = action.payload.data;
            })
            .addCase(getStockInSuper.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(getStockInAdmin.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(getStockInAdmin.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.stockInDataAdmin = action.payload.data;
            })
            .addCase(getStockInAdmin.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(getStockOutSuper.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(getStockOutSuper.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.stockOutDataSuper = action.payload.data;
            })
            .addCase(getStockOutSuper.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
            .addCase(getStockOutAdmin.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(getStockOutAdmin.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.stockOutDataAdmin = action.payload.data;
            })
            .addCase(getStockOutAdmin.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload;
            })
    }
});

export default godownReportSlice.reducer;
