import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../apiConfig";
import { enqueueSnackbar } from "notistack";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};


export const getGodownProduct = createAsyncThunk(
    "godown/getProduct",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}gowdowns/product`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar("Something went wrong!", { variant: "error",  className: 'outlined-snackbar' });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {

            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const initialState = {
    AllData: [],
    getStatus: 'idle',
    error: null,
};

const godownProductSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getGodownProduct.pending, (state) => {
                state.getStatus = 'pending';
            })
            .addCase(getGodownProduct.fulfilled, (state, action) => {
                state.getStatus = 'succeeded';
                state.AllData = action.payload.data;
            })
            .addCase(getGodownProduct.rejected, (state, action) => {
                state.getStatus = 'failed';
                state.error = action.payload;
            })
    }
});

export default godownProductSlice.reducer;
