import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrl } from "../../../apiConfig";
import { enqueueSnackbar } from "notistack";

const getTokenFromLocalStorage = () => {
    return localStorage.getItem("token");
};

export const getUploads = createAsyncThunk(
    "super_admin/getUploads",
    async (_, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/file`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

export const addUploads = createAsyncThunk(
    "uploads",
    async (datas, { rejectWithValue }) => {
        try {
            const token = getTokenFromLocalStorage();
            const formData = new FormData();

            // Append the files to the FormData object
            if (datas.title) formData.append('title', datas.title);
            formData.append('description', 'NA');
            if (datas.catalog) formData.append('catalog', datas.catalog);
            if (datas.image) formData.append('image', datas.image);

            const response = await fetch(`${apiUrl}super-admin/file/add`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
                body: formData,
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }

            const data = await response.json();
            enqueueSnackbar( "Added successfully", { 
                variant: "success", 
                className: 'outlined-snackbar' 
            });
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred while adding the subCategory.' });
        }
    }
);

export const deleteUploads = createAsyncThunk(
    "deleteUploads",
    async (id, { rejectWithValue }) => {

        try {
            const token = getTokenFromLocalStorage();
            const response = await fetch(`${apiUrl}super-admin/file/delete/${id}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    Accept: "*/*",
                },
            });

            if (!response.ok) {
                const errorData = await response.json();
                enqueueSnackbar(errorData.error || "Something went wrong!", { 
                    variant: "error", 
                    className: 'outlined-snackbar' 
                });
                return rejectWithValue(errorData);
            }
            const data = await response.json();
            enqueueSnackbar( "Deleted successfully", { 
                variant: "success", 
                className: 'outlined-snackbar' 
            });
            return data;
        } catch (error) {
            return rejectWithValue({ message: 'An error occurred during the request.' });
        }
    }
);

const initialState = {
    uploadsData: [],
    getStat: 'idle',
    addStat: 'idle',
    deleteStat: 'idle',
    error: null,
};

const uploadsSlice = createSlice({
    name: 'uploads',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getUploads.pending, (state) => {
                state.getStat = 'pending';
            })
            .addCase(getUploads.fulfilled, (state, action) => {
                state.getStat = 'succeeded';
                state.uploadsData = action.payload.data;
            })
            .addCase(getUploads.rejected, (state, action) => {
                state.getStat = 'failed';
                state.error = action.payload;
            })
            .addCase(addUploads.pending, (state) => {
                state.addStat = 'pending';
            })
            .addCase(addUploads.fulfilled, (state, action) => {
                state.addStat = 'succeeded';
            })
            .addCase(addUploads.rejected, (state, action) => {
                state.addStat = 'failed';
                state.error = action.payload;
            })
            .addCase(deleteUploads.pending, (state) => {
                state.deleteStat = 'pending';
            })
            .addCase(deleteUploads.fulfilled, (state, action) => {
                state.deleteStat = 'succeeded';
            })
            .addCase(deleteUploads.rejected, (state, action) => {
                state.deleteStat = 'failed';
                state.error = action.payload;
            });
    }
});

export default uploadsSlice.reducer;
