import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import MultipleStopIcon from "@mui/icons-material/MultipleStop";
import Loader from "../../components/utils/Loader";
import { AiOutlinePicture } from "react-icons/ai";
import { getBrand } from "../../redux/Slices/SuperAdmin/inventory/brandSlice";
import { getSize } from "../../redux/Slices/Measures/sizeSlice";
import { getCategory } from "../../redux/Slices/SuperAdmin/categorySlice";
import { getGodown } from "../../redux/Slices/SuperAdmin/inventory/godownSlice";
import { getFinish } from "../../redux/Slices/SuperAdmin/finishSlice";
import {
  Download as DownloadIcon,
  RollerShadesOutlined,
} from "@mui/icons-material";

const Transaction = ({ propData }) => {
  const invalidDateProducts = propData.filter((product) => {
    const date = product.date;
    return date === null || date === undefined || date === "0000/00/00";
  });




  const dispatch = useDispatch();
  const { allInvDataSuper = [], otherStatSuper } = useSelector(
    (state) => state.product
  );
  const { allInvDataAdmin = [], otherStatAdmin } = useSelector(
    (state) => state.adminProduct
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const roleId = localStorage.getItem("roleId");
  const [showImage, setShowImage] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedGodown, setSelectedGodown] = useState("");
  const [selectedBrand, setSelectedBrand] = useState("");
  const [selectedSize, setSelectedSize] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedFinish, setSelectedFinish] = useState("");
  const { godownData = [] } = useSelector((state) => state.superAdminGodown);
  const { brandData = [] } = useSelector((state) => state.superAdminBrand);
  const { sizeData = [] } = useSelector((state) => state.superAdminSize);
  const { data = [] } = useSelector((state) => state.superAdminCategory);
  const { finishData = [] } = useSelector((state) => state.finish);

  // const generateSummaryData = () => {
  //   const summaryMap = new Map();

  //   (roleId == "1" || roleId == "4" ? allInvDataSuper : allInvDataAdmin).forEach(
  //     (product) => {
  //       product.inventories.forEach((inventory) => {
  //         const godownName = inventory.godowns_name?.name || "Unknown";

  //         // Normalize stock-in date
  //         const stockInDate = inventory.product_date
  //           ? new Date(inventory.product_date).toLocaleDateString("en-GB", {
  //               year: "numeric",
  //               month: "2-digit",
  //               day: "2-digit",
  //             })
  //           : null;

  //         // Process Stock In
  //         if (stockInDate) {
  //           const key = `${product.sku}_${godownName}_${stockInDate}`;
  //           if (!summaryMap.has(key)) {
  //             summaryMap.set(key, {
  //               sku: product.sku,
  //               name: product.name,
  //               brand: product.brandname,
  //               category: product.categoriename,
  //               subcategory: product.subcategoriesname,
  //               size: product.size,
  //               color: product.color,
  //               finish: product.finish,
  //               thickness: product.thickness,
  //               uom: product.uom,
  //               ImageUrl: product.ImageUrl,
  //               godown: godownName,
  //               date: stockInDate,
  //               stockInQty: 0,
  //               stockOutQty: 0, // Default
  //             });
  //           }
  //           summaryMap.get(key).stockInQty += Number(inventory.total_qty) || 0;
  //         }

  //         // Process Stock Out
  //         if (inventory.stockout && inventory.stockout.length > 0) {
  //           inventory.stockout.forEach((stockout) => {
  //             const stockOutDate = stockout.product_date
  //               ? new Date(stockout.product_date).toLocaleDateString("en-GB", {
  //                   year: "numeric",
  //                   month: "2-digit",
  //                   day: "2-digit",
  //                 })
  //               : null;

  //             if (stockOutDate) {
  //               const stockOutGodown = inventory.godowns_name?.name || "Unknown"; // Get stock-out godown

  //               const key = `${product.sku}_${stockOutGodown}_${stockOutDate}`;
  //               if (!summaryMap.has(key)) {
  //                 summaryMap.set(key, {
  //                   sku: product.sku,
  //                   name: product.name,
  //                   brand: product.brandname,
  //                   category: product.categoriename,
  //                   subcategory: product.subcategoriesname,
  //                   size: product.size,
  //                   color: product.color,
  //                   finish: product.finish,
  //                   thickness: product.thickness,
  //                   uom: product.uom,
  //                   ImageUrl: product.ImageUrl,
  //                   godown: stockOutGodown,
  //                   date: stockOutDate,
  //                   stockInQty: 0,
  //                   stockOutQty: 0,
  //                 });
  //               }
  //               summaryMap.get(key).stockOutQty +=
  //                 Number(stockout.previous_qty) || 0;
  //             }
  //           });
  //         }
  //       });
  //     }
  //   );

  //   // Sort the summary data by date
  //   return Array.from(summaryMap.values()).sort(
  //     (a, b) =>
  //       new Date(b.date.split("/").reverse().join("-")) -
  //       new Date(a.date.split("/").reverse().join("-"))
  //   );
  // };

  const [summaryData, setSummaryData] = useState();

  useEffect(() => {
    setSummaryData(propData);
  }, [propData]);

  const filteredData = summaryData?.filter((item) => {
    const matchesSearch =
      searchQuery == "" ||
      item?.sku?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      item?.brand?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      item?.category?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      item?.subcategory?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      item?.color?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      item?.finsih?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      item?.thickness?.toLowerCase().includes(searchQuery?.toLowerCase()) ||
      item?.name?.toLowerCase().includes(searchQuery?.toLowerCase());
    const matchesDate =
      (!fromDate ||
        new Date(item.date?.split("/").reverse().join("-")) >=
          new Date(fromDate)) &&
      (!toDate ||
        new Date(item.date?.split("/").reverse().join("-")) <=
          new Date(toDate));

    const matchesGodown = !selectedGodown || item.godown === selectedGodown;
    const matchesBrand = !selectedBrand || item.brand === selectedBrand;
    const matchesCategory =
      !selectedCategory || item.category === selectedCategory;
    const matchesSize = !selectedSize || item.size === selectedSize;
    const matchesFinish = !selectedFinish || item.finish === selectedFinish;

    return (
      matchesSearch &&
      matchesDate &&
      matchesGodown &&
      matchesBrand &&
      matchesCategory &&
      matchesSize &&
      matchesFinish
    );
  });

  useEffect(() => {
    dispatch(getBrand());
    dispatch(getSize());
    dispatch(getCategory());
    dispatch(getGodown());
    dispatch(getFinish());
  }, []);

  const handleGodownChange = (event) => {
    setSelectedGodown(event.target.value);
  };

  const handleBrandChange = (event) => {
    setSelectedBrand(event.target.value);
  };
  const handleSizeChange = (event) => {
    setSelectedSize(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const handleFinishChange = (event) => {
    setSelectedFinish(event.target.value);
  };

  const isPending = otherStatAdmin == "pending" || otherStatSuper == "pending";

  const downloadCSV = (dump) => {
    const csvRows = [];
    csvRows.push([
      roleId === "1" || roleId === "4" ? "GODOWN" : "",
      "SKU",
      "NAME",
      "BRAND",
      "CATEGORY",
      "SUB-CATEGORY",
      "BATCH",
      "SIZE",
      "COLOR",
      "STOCK IN QTY",
      "STOCK OUT QTY",
      "RETURN QTY",
      "DAMAGE QTY",
      "UOM",
      "FINISH",
      "THICKNESS",
      "MRP",
      "DATE",
    ]);

    let data;
    if (dump) {
      data = propData;
    } else {
      data = filteredData
        ? filteredData?.slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
          )
        : [];
    }

    data?.forEach((row) => {
      csvRows.push([
        roleId === "1" || roleId === "4" ? row?.godown : "",
        row.sku,
        row?.name,
        row?.brand,
        row?.category,
        row?.subcategory,
        row?.batch,
        row?.size,
        row?.color,
        row?.stockInQty,
        row?.stockOutQty,
        row?.returnQty,
        row?.damageQty,
        row?.uom,
        row?.finish,
        row?.thickness,
        row?.mrp,
        new Date(row?.date).toLocaleDateString("en-GB", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        }),
      ]);
    });

    const csvString = csvRows.map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.setAttribute("download", "transaction_report.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value;
    setRowsPerPage(
      value === "All"
        ? filteredData.flatMap((product) => product).length
        : parseInt(value, 10)
    );
    setPage(0);
  };

  return (
    <>
      <div className="p-3 bg-gray-50 shadow-lg rounded-lg">
        <div className="flex justify-between items-center mb-4 bg-gradient-to-r  from-gray-600 to-gray-600 rounded-t-lg p-5 ">
          <span className="flex flex-col w-full">
            {/* top filter */}
            <Box
              display="flex"
              flexDirection="row"
              paddingTop={1}
              paddingBottom={2}
              gap={2}
            >
              {roleId === "1" && (
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    id="godown-select-label"
                    sx={{ color: "#FFFFFF" }}
                  >
                    Godown
                  </InputLabel>
                  <Select
                    labelId="godown-select-label"
                    value={selectedGodown}
                    onChange={handleGodownChange}
                    label="Godown"
                    sx={{
                      bgcolor: "#90E0",
                      color: "#FFFFFF",
                      "& .MuiSelect-icon": {
                        color: "#FFFFFF",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FFFFFF",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#FFFFFF",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#FFFFFF",
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: "#FFFFFF",
                      },
                      "& .MuiSelect-select": {
                        color: "#FFFFFF",
                      },
                    }}
                  >
                    <MenuItem value="">
                      <em>All Godowns</em>
                    </MenuItem>
                    {godownData.map((godown) => (
                      <MenuItem key={godown.id} value={godown.name}>
                        {godown.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}

              <FormControl fullWidth variant="outlined">
                <InputLabel id="brand-select-label" sx={{ color: "#FFFFFF" }}>
                  Brand
                </InputLabel>
                <Select
                  labelId="brand-select-label"
                  value={selectedBrand}
                  onChange={handleBrandChange}
                  label="Brand"
                  sx={{
                    bgcolor: "#90E00",
                    color: "#FFFFFF",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FFFFFF",
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Brands</em>
                  </MenuItem>
                  {brandData.map((brand) => (
                    <MenuItem key={brand.id} value={brand.name}>
                      {brand.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined">
                <InputLabel
                  id="category-select-label"
                  sx={{ color: "#FFFFFF" }}
                >
                  Category
                </InputLabel>
                <Select
                  labelId="category-select-label"
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  label="Category"
                  sx={{
                    bgcolor: "#90E0",
                    color: "#FFFFFF",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FFFFFF",
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Categories</em>
                  </MenuItem>
                  {data.map((category) => (
                    <MenuItem key={category.id} value={category.name}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined">
                <InputLabel id="size-select-label" sx={{ color: "#FFFFFF" }}>
                  size
                </InputLabel>
                <Select
                  labelId="size-select-label"
                  value={selectedSize}
                  onChange={handleSizeChange}
                  label="Size"
                  sx={{
                    bgcolor: "#90E00",
                    color: "#FFFFFF",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root": {
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FFFFFF",
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Sizes</em>
                  </MenuItem>
                  {sizeData.map((size) => (
                    <MenuItem key={size.id} value={size.name}>
                      {size.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl fullWidth variant="outlined">
                <InputLabel id="finish-select-label" sx={{ color: "#FFFFFF" }}>
                  Finish
                </InputLabel>
                <Select
                  labelId="finish-select-label"
                  value={selectedFinish}
                  onChange={handleFinishChange}
                  label="Finish"
                  sx={{
                    bgcolor: "#90E0",
                    color: "#FFFFFF",
                    "& .MuiSelect-icon": {
                      color: "#FFFFFF",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "&:hover .MuiOutlinedInput-notchedOutline": {
                      borderColor: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root ```javascript": {
                      color: "#FFFFFF",
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: "#FFFFFF",
                    },
                    "& .MuiSelect-select": {
                      color: "#FFFFFF",
                    },
                  }}
                >
                  <MenuItem value="">
                    <em>All Finishes</em>
                  </MenuItem>
                  {finishData.map((finish) => (
                    <MenuItem key={finish.id} value={finish.name}>
                      {finish.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <span className='flex items-center justify-between -mt-4"'>
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="border border-blue-600 rounded-lg w-[20rem] h-11 py-2 px-2 "
                placeholder="Search..."
              />
              <div className="flex items-center">
                <input
                  type="date"
                  value={fromDate}
                  onChange={(e) => setFromDate(e.target.value)}
                  className="border border-blue-600 rounded-lg w-[9rem] py-2 px-2"
                />
                <MultipleStopIcon className="text-white mx-2" />
                <input
                  type="date"
                  value={toDate}
                  onChange={(e) => setToDate(e.target.value)}
                  className="border border-blue-600 rounded-lg w-[9rem] py-2 px-2"
                />
              </div>
              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={() => downloadCSV()}
                className="bg-blue-600 text-white hover:bg-blue-700 transition-colors"
              >
                Download
              </Button>
              <Button
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={() => downloadCSV(true)}
                sx={{ backgroundColor: "#f5cb42" }}
                className="bg-yellow-600 text-white hover:bg-yellow-600 transition-colors"
              >
                Dump
              </Button>
            </span>
          </span>
        </div>

        <TableContainer
          component={Paper}
          className="rounded-lg mt-[-2rem] shadow-md "
        >
          <Table
            aria-label="customized table"
            sx={{ width: "100%", tableLayout: "fixed" }}
          >
            <TableHead className="bg-gradient-to-r from-gray-600 to-gray-600">
              <TableRow className="">
                <TableCell
                  sx={{ color: "white", width: "80px" }}
                  className="text-white text-center"
                >
                  SL No.
                </TableCell>
                {(roleId === "1" || roleId === "4") && (
                  <TableCell
                    sx={{ color: "white", width: "200px" }}
                    className="text-center text-xs font-bold py-3"
                  >
                    Godown
                  </TableCell>
                )}

                <TableCell
                  sx={{ color: "white", width: "140px" }}
                  className="text-white text-center"
                >
                  SKU
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "240px" }}
                  className="text-white text-center"
                >
                  Name
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "150px" }}
                  className="text-white text-center"
                >
                  Brand
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "160px" }}
                  className="text-white text-center"
                >
                  Category
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "160px" }}
                  className="text-white text-center"
                >
                  Sub - Category
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "80px" }}
                  className="text-white text-center"
                >
                  Batch
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "120px" }}
                  className="text-white text-center"
                >
                  Size
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "150px" }}
                  className="text-white text-center"
                >
                  Color
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "100px" }}
                  className="text-white text-center"
                >
                  Stock In
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "100px" }}
                  className="text-white text-center"
                >
                  Stock Out
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "100px" }}
                  className="text-white text-center"
                >
                  Return
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "100px" }}
                  className="text-white text-center"
                >
                  Damage
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "80px" }}
                  className="text-white text-center"
                >
                  UOM
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "160px" }}
                  className="text-white text-center"
                >
                  Finish
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "100px" }}
                  className="text-white text-center"
                >
                  Thickness
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "100px" }}
                  className="text-white text-center"
                >
                  MRP
                </TableCell>
                <TableCell
                  sx={{ color: "white", width: "120px" }}
                  className="text-white text-center"
                >
                  Date
                </TableCell>

                <TableCell
                  sx={{ color: "white", width: "100px" }}
                  className="text-white text-center"
                >
                  Image
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
                {filteredData
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow key={index}>
                      <TableCell className="text-center">
                        {index + 1 + page * rowsPerPage}
                      </TableCell>

                      {(roleId === "1" || roleId === "4") && (
                        <TableCell className="text-center py-1">
                          {row.godown}
                        </TableCell>
                      )}

                      <TableCell className="text-center py-1">
                        {row.sku}
                      </TableCell>
                      <TableCell className="text-center py-1">
                        {row.name}
                      </TableCell>
                      <TableCell className="text-center py-1">
                        {row.brand}
                      </TableCell>
                      <TableCell className="text-center py-1">
                        {row.category}
                      </TableCell>
                      <TableCell className="text-center py-1">
                        {row.subcategory}
                      </TableCell>
                      <TableCell className="text-center py-1">
                        {row.batch}
                      </TableCell>
                      <TableCell className="text-center py-1">
                        {row.size}
                      </TableCell>
                      <TableCell className="text-center py-1">
                        {row.color}
                      </TableCell>
                      <TableCell className="text-center py-1 bg-green-300">
                        {row.stockInQty}
                      </TableCell>
                      <TableCell className="text-center bg-blue-300">
                        {row.stockOutQty}
                      </TableCell>
                      <TableCell className="text-center bg-yellow-300">
                        {row.returnQty}
                      </TableCell>
                      <TableCell className="text-center bg-red-300">
                        {row.damageQty}
                      </TableCell>
                      <TableCell className="text-center">
                        {row.uom || "N/A"}
                      </TableCell>
                      <TableCell className="text-center py-1">
                        {row.finish}
                      </TableCell>
                      <TableCell className="text-center py-1">
                        {row.thickness}
                      </TableCell>
                      <TableCell className="text-center py-1">
                        {row.mrp}
                      </TableCell>
                      <TableCell className="text-center py-1">
                        {new Date(row.date)
                          ?.toLocaleDateString("en-GB")
                          .split("/")
                          .join("/")}
                      </TableCell>
                      <TableCell>
                        {row.ImageUrl !==
                          "https://inventory.risapp.co.in/public/storage" && (
                          <div
                            className="cursor-pointer h-1 w-1 items-center"
                            onClick={() => setShowImage(row.ImageUrl)}
                          >
                            <AiOutlinePicture size={24} />
                            {showImage === row.ImageUrl && (
                              // Modal Background
                              <div
                                className="fixed inset-0 z-20 flex items-center justify-center bg-black bg-opacity-10"
                                onClick={() => setShowImage(false)}
                              >
                                {/* Modal Content */}
                                <div
                                  className="relative mt-16 bg-white rounded-lg shadow-lg p-4 overflow-hidden transform transition-all duration-300 ease-in-out"
                                  style={{ width: "80%", maxWidth: "500px" }}
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  {/* Image */}
                                  <div className="flex justify-center">
                                    <img
                                      src={row.ImageUrl}
                                      alt="Image"
                                      className="w-full h-full object-cover rounded-lg"
                                      style={{ maxHeight: "70vh" }}
                                    />
                                  </div>
                                  {/* Action Buttons */}
                                  <div className="mt-4 flex justify-center space-x-4">
                                    <button
                                      className="bg-orange-500 hover:bg-orange-600 text-white font-bold py-2 px-6 rounded-lg"
                                      onClick={() => setShowImage(false)}
                                    >
                                      Close
                                    </button>
                                    <button
                                      className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg"
                                      onClick={() =>
                                        window.open(row.ImageUrl, "_blank")
                                      }
                                    >
                                      Open in New Tab
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                <TableRow className="font-bold bg-orange-200">
                  <TableCell
                    align="center"
                    sx={{
                      fontSize: "1.1rem",
                      fontWeight: "bold",
                      color: "#000",
                    }}
                    className="text-center"
                    colSpan={roleId === "2" ? 9 : 10}
                  >
                    Total
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff" }}
                    className="text-center bg-green-600"
                  >
                    {filteredData
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.reduce(
                        (sum, row) => sum + (Number(row.stockInQty) || 0),
                        0
                      )}
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff" }}
                    className="text-center bg-blue-600"
                  >
                    {filteredData
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.reduce(
                        (sum, row) => sum + (Number(row.stockOutQty) || 0),
                        0
                      )}
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff" }}
                    className="text-center bg-yellow-600"
                  >
                    {filteredData
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.reduce(
                        (sum, row) => sum + (Number(row.returnQty) || 0),
                        0
                      )}
                  </TableCell>
                  <TableCell
                    sx={{ color: "#fff" }}
                    className="text-center bg-red-600"
                  >
                    {filteredData
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      ?.reduce(
                        (sum, row) => sum + (Number(row.damageQty) || 0),
                        0
                      )}
                  </TableCell>
                  <TableCell className="text-center" colSpan={6}></TableCell>
                </TableRow>
              </>
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={filteredData?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[10, 25, 50, 100, "All"]}
          className="mt-4"
        />
      </div>
    </>
  );
};

export default Transaction;
